import { useState } from "react";

import { Link, Typography, MenuItem, Menu, Box } from "@mui/material";
import { styles } from "../NavMenu.style";
import LoginIcon from "@mui/icons-material/Login";
import PhotoIcon from "@mui/icons-material/Photo";
import PersonIcon from "@mui/icons-material/Person";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import CollectionsIcon from "@mui/icons-material/Collections";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HistoryIcon from "@mui/icons-material/History";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

const WideScreenMenu = ({ user, logout }) => {
  const [anchorElAdmin, setAnchorElAdmin] = useState(null);
  const isAdminOpen = Boolean(anchorElAdmin);
  const handleClickAdmin = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };
  const handleCloseAdmin = () => {
    setAnchorElAdmin(null);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Link href="/contact" sx={styles.link}>
        <MenuItem sx={styles.menuItem}>
          <ContactPageIcon sx={{ mr: 1 }} />
          <Typography variant="overline">Contact</Typography>
        </MenuItem>
      </Link>

      {user && (
        <Box sx={{ display: "flex" }}>
          <Link href="/themes" sx={styles.link}>
            <MenuItem sx={styles.menuItem}>
              <PhotoIcon sx={{ mr: 1 }} />
              <Typography variant="overline">Thèmes</Typography>
            </MenuItem>
          </Link>
          <Link href="/recent" sx={styles.link}>
            <MenuItem sx={styles.menuItem}>
              <HistoryIcon sx={{ mr: 1 }} />
              <Typography variant="overline">Récemment ajouté</Typography>
            </MenuItem>
          </Link>

          {user.permissions.some(
            (permission) =>
              permission === "u" || permission === "g" || permission === "r"
          ) && (
            <Box sx={{ display: "flex" }}>
              <MenuItem
                onClick={handleClickAdmin}
                size="small"
                sx={styles.menuItem}
                aria-controls={isAdminOpen ? "admin-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isAdminOpen ? "true" : undefined}
              >
                <PersonIcon sx={{ mr: 1 }} />
                <Typography variant="overline">Administration</Typography>
              </MenuItem>

              <Menu
                anchorEl={anchorElAdmin}
                id="admin-menu"
                open={isAdminOpen}
                onClose={handleCloseAdmin}
                onClick={handleCloseAdmin}
                PaperProps={styles.paperMenuProps}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {user.permissions.some((permission) => permission === "r") && (
                  <Box>
                    <Link href="/add-resources" sx={styles.menuLink}>
                      <MenuItem key="addResources" sx={styles.menuItem}>
                        <AddCircleIcon sx={styles.icon} />
                        <Typography>Ajouter du contenu</Typography>
                      </MenuItem>
                    </Link>
                    <Link href="/manage-collections" sx={styles.menuLink}>
                      <MenuItem key="manageCollections" sx={styles.menuItem}>
                        <CollectionsIcon sx={styles.icon} />
                        <Typography>Gestion des albums</Typography>
                      </MenuItem>
                    </Link>
                    <Link href="/manage-themes" sx={styles.menuLink}>
                      <MenuItem key="manageThemes" sx={styles.menuItem}>
                        <ViewModuleIcon sx={styles.icon} />
                        <Typography>Gestion des thèmes</Typography>
                      </MenuItem>
                    </Link>
                  </Box>
                )}

                {user.permissions.some((permission) => permission === "u") && (
                  <Link href="/manage-users" sx={styles.menuLink}>
                    <MenuItem key="manageUsers" sx={styles.menuItem}>
                      <AccountCircleIcon sx={styles.icon} />
                      <Typography>Gestion des utilisateurs</Typography>
                    </MenuItem>
                  </Link>
                )}

                {user.permissions.some((permission) => permission === "g") && (
                  <Link href="/manage-usergroups" sx={styles.menuLink}>
                    <MenuItem key="manageUsergroups" sx={styles.menuItem}>
                      <SupervisedUserCircleIcon sx={styles.icon} />
                      <Typography>
                        Gestion des groupes d'utilisateurs
                      </Typography>
                    </MenuItem>
                  </Link>
                )}
              </Menu>
            </Box>
          )}
        </Box>
      )}

      {user ? (
        <MenuItem sx={styles.menuItem} onClick={logout}>
          <LoginIcon sx={{ mr: 1 }} />
          <Typography variant="overline">Déconnexion</Typography>
        </MenuItem>
      ) : (
        <Link href="/login" sx={styles.link}>
          <MenuItem sx={styles.menuItem}>
            <LoginIcon sx={{ mr: 1 }} />
            <Typography variant="overline">Connexion</Typography>
          </MenuItem>
        </Link>
      )}
    </Box>
  );
};

export default WideScreenMenu;
