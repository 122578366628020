import React from "react";
import { Typography, Box } from "@mui/material";
import { formatBytes } from "../../../../utils/Utils";

const styles = {
  label: {
    width: "150px",
    display: "inline-block",
    textAlign: "left",
    marginRight: "10px",
  },
  value: {
    display: "inline-block",
  },
};

const translations = {
  "Camera make / model": "Marque / modèle de l'appareil",
  Credit: "Crédit",
  Description: "Description"
};

const MetadataDisplay = ({ resource }) => {
  const metadata = resource.metadata;
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="subtitle1">
        <strong>Tailles</strong>
      </Typography>
      <Typography variant="body1">
        <span style={styles.label}>Fichier d'origine </span>
        <span style={styles.value}>≈ {formatBytes(resource.size)}</span>

        <br />
        <span style={styles.label}>Web-écran </span>
        <span style={styles.value}>≈ {formatBytes(resource.size / 8)}</span>
        <br />
        <span style={styles.label}>Impression basse </span>
        <span style={styles.value}>≈ {formatBytes(resource.size / 18)}</span>
        <br />
        <br />
      </Typography>
      <Typography variant="subtitle1">
        <strong>Tags</strong>
      </Typography>
      <Typography variant="body1">
      <span style={styles.value}>
        {resource.keywords.map((keyword) => (
            keyword + " "
        ))}
        </span>
      </Typography>
      {Object.entries(metadata).map(([key, value]) => {
        if (value !== null) {
          return (
            <Box key={key}>
              <Typography variant="subtitle1">
                <strong>{translations[key] || key}</strong>
              </Typography>
              <Typography variant="body1">{value}</Typography>
              <br />
              <br />
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
};

export default MetadataDisplay;
