import { useState } from "react";

import EditUser from "./editUser/EditUser";
import DeleteUser from "./deleteUser/DeleteUser";

import { IconButton, TableCell, TableRow, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const UserRow = ({ user, usergroups, setUsers }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const rowBody = (
    <>
      <TableCell component="th" align="center">
        <strong>{user.ref}</strong>
      </TableCell>
      <TableCell align="center">{user.fullname}</TableCell>
      <TableCell align="center">{user.username}</TableCell>
      <TableCell align="center">{user.email}</TableCell>
      <TableCell align="center">{user.usergroupName}</TableCell>
      <TableCell align="center">
        {new Date(user.created).toLocaleDateString("fr")}
      </TableCell>
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <IconButton
            onClick={() => {
              setIsEditOpen(true);
            }}
          >
            <EditIcon sx={{ color: "#027FA2" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setIsDeleteOpen(true);
            }}
          >
            <DeleteForeverIcon sx={{ color: "#C34D60" }} />
          </IconButton>
        </Box>
      </TableCell>
    </>
  );

  return (
    <>
      <TableRow>{rowBody}</TableRow>
      <EditUser
        user={user}
        usergroups={usergroups}
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        setUsers={setUsers}
      />
      <DeleteUser
        user={user}
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
        setUsers={setUsers}
      />
    </>
  );
};

export default UserRow;
