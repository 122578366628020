export const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    py: 1,
    px: 3,
  },

  button: {
    width: "150px",
  },

  flexSpace: {
    width: "100%",
    displat: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },

  errorText: {
    fontSize: "12px",
    color: "#C34D60",
    mt: 2,
  },
};
