export const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    p: { xs: 0, md: 1, lg: 2 },
  },

  card: {
    p: 4,
    my: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "20px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },

  submitButton: {
    mt: 2,
    width: "200px",
  },

  icon: {
    color: "#027FA2",
  },

  field: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    backgroundColor: "white",
    borderRadius: "20px",
    width: { xs: "230px", md: "250px", lg: "300px" },
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  errorText: {
    fontSize: "11px",
    color: "#C34D60",
    mt: 2,
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  flexEvenly: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },

  link: {
    "&:hover": {
      cursor: "pointer",
    },
    mt: 2,
  },
};
