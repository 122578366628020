export const styles = {
  appBar: {
    color: "white",
    background: "#027FA2",
    height: "75px",
    display: "flex",
    justifyContent: "center",
    zIndex: 10,
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    px: 2,
    borderRadius: "40px",
    mx: 1,
  },
  link: { color: "white", textDecoration: "none" },
  menuLink: { color: "black", textDecoration: "none" },
  icon: { mr: 2, color: "#027FA2" },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
