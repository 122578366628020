export const styles = {
  title: {
    textAlign: "center",
    mt: 15,
    mb: 3,
    fontSize: "35px",
  },

  tableContainer: { mb: 5, borderRadius: "20px" },
  headTable: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },

  addButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    minWidth: "300px",
    my: 5,
    mx: "auto",
    py: 1,
    px: 2,
  },
};
