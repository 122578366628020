export const styles = {

 previewImageContainer: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    padding: "5px",
    borderRadius: "5px",
  },
  previewImage: {
    maxWidth: "80vw",
    maxHeight: "80vh",
  },

  menuItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "30px",
    padding: "16px",
    boxSizing: "border-box",
    overflow: "hidden",
  },

  pagination: {
    mx: { xs: 0, md: 2 },
    my: 2,
    ul: {
      [`& .MuiPaginationItem-root`]: {
        color: "#027FA2",
      },
    },
  },

  paginationContainer: {
    display: "flex",
    justifyContent: { xs: "center", md: "end" },
    alignItems: "center",
    px: { xs: 0, md: 4, lg: 6 },
  },

  imageContainer: {
    width: "100%",
    paddingTop: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  field: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: "20px",
    mb: 2,
    width: "250px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  downloadContainer: {
    position: "fixed",
    top: 75,
    left: 0,
    width: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: { xs: "column", md: "row" },
    color: "white",
    px: { xs: 0, md: 2 },
    py: 2,
    zIndex: 2,
    backgroundColor: "rgb(53, 74, 84)",
  },

  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    px: { xs: 0, md: 4 },
    py: { xs: 5, md: 2 },
  },

  preview: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "20px",
  },

  enlarge: {
    position: "relative",
    width: "100%",
    maxHeight: "50vh",
    objectFit: "contain",
  },

  phoneEnlarge: {
    margin: "60px auto",
    position: "relative",
    width: "150px",
    maxHeight: "50vh",
    objectFit: "contain",
  },

  selectIcon: {
    position: "absolute",
    top: "8px",
    left: "8px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "50%",
    zIndex: 1,
  },

  zoomIcon: {
    position: "absolute",
    bottom: "8px",
    right: "8px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "50%",
    zIndex: 1,
  },

  settingsIcon: {
    position: "absolute",
    bottom: "8px",
    left: "8px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "50%",
    zIndex: 1,
  },

  name: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "90%",
    textOverflow: "ellipsis",
    textAlign: "center",
  },

  navigationContainer: {
    display: "flex",
    alignItems: "center",
    mx: 2,
  },

  enlargeContainer: {
    display: "flex",
    flexDirection: "column",
    px: { xs: 0, md: 5, lg: 10 },
    py: { xs: 0, md: 2, lg: 4 },
    width: { xs: "100%", md: "600px", lg: "800px" },
    height: { xs: "70vh", md: "70vh", lg: "80vh" },
  },

  enlargeArrowForwardButton: {
    position: "absolute",
    top: "25%",
    right: 16,
    transform: "translateY(-50%)",
    zIndex: 1,
  },

  enlargeArrowBackwardButton: {
    position: "absolute",
    top: "25%",
    left: 16,
    transform: "translateY(-50%)",
    zIndex: 1,
  },

  flexEnd: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  sortBar: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: "20px",
    mx: 2,
    mb: 2,
    width: "300px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },
};
