import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { UCAButton } from "../../utils/Styled";

import {
  getToken,
  resetPassword,
} from "../../api/users/password/passwordHandler";

import Loading from "../../components/loading/Loading";
import NotFound from "../../components/errors/NotFound";

import { styles } from "./ResetPassword.style";

import {
  Box,
  Card,
  InputAdornment,
  TextField,
  FormControl,
  IconButton,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SyncLockIcon from "@mui/icons-material/SyncLock";

const ResetPassword = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const tokenId = queryParams.get("token");

  const defaultError = {
    hasError: false,
    message: "",
  };
  const [formValues, setFormValues] = useState({
    password: "",
    confirmedPassword: "",
  });
  const [error, setError] = useState(defaultError);
  const [token, setToken] = useState();
  const [hasApiError, setHasApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [confirmedPasswordInputType, setConfirmedPasswordInputType] =
    useState("password");
  const navigateTo = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const aToken = await getToken(tokenId);
      setToken(aToken);
    } catch {
      setHasApiError(true);
    }
  }, [tokenId]);

  useEffect(() => {
    document.title = "Réinitialisation du mot de passe";
    fetchData();
  }, [fetchData]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    showPassword
      ? setPasswordInputType("password")
      : setPasswordInputType("text");
  };

  const handleClickShowConfirmedPassword = () => {
    setShowConfirmedPassword(!showConfirmedPassword);
    showConfirmedPassword
      ? setConfirmedPasswordInputType("password")
      : setConfirmedPasswordInputType("text");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    setError(defaultError);

    if (formValues.password !== formValues.confirmedPassword) {
      setError({
        hasError: true,
        message: "Les mots de passe ne correspondent pas.",
      });
      setIsLoading(false);
      return;
    }

    const aPasswordRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );

    if (!aPasswordRegex.test(formValues.password)) {
      setError({
        hasError: true,
        message:
          "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre.",
      });
      setIsLoading(false);
      return;
    }

    try {
      await resetPassword(token.userId, {
        tokenId: token._id,
        password: formValues.password,
      });

      navigateTo("/login");
    } catch (err) {
      setError({
        hasError: true,
        message: "Une erreur est survenue, veuillez réessayer.",
      });
    }

    setIsLoading(false);
  };

  if (hasApiError) {
    return <NotFound />;
  }

  if (!token) {
    return <Loading />;
  }

  return (
    <Box sx={styles.container}>
      <Card sx={styles.card}>
        <SyncLockIcon sx={{ ...styles.icon, mb: 1 }} />
        <Typography variant="overline" sx={{ fontSize: "10px" }}>
          Réinitialisation du mot de passe
        </Typography>
        <FormControl sx={{ my: 1 }}>
          <form>
            <TextField
              id="password-input"
              name="password"
              type={passwordInputType}
              label="Nouveau mot de passe"
              required={true}
              variant="outlined"
              value={formValues.password}
              onChange={handleInputChange}
              sx={styles.field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={styles.icon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon sx={styles.icon} />
                      ) : (
                        <VisibilityOffIcon sx={styles.icon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              id="confirmed-password-input"
              name="confirmedPassword"
              type={confirmedPasswordInputType}
              label="Confirmation du mot de passe"
              required={true}
              variant="outlined"
              value={formValues.confirmedPassword}
              sx={styles.field}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={styles.icon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmedPassword}
                    >
                      {showConfirmedPassword ? (
                        <VisibilityIcon sx={styles.icon} />
                      ) : (
                        <VisibilityOffIcon sx={styles.icon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </FormControl>
        <UCAButton
          sx={styles.submitButton}
          onClick={handleSave}
          disabled={!formValues.confirmedPassword || !formValues.password}
        >
          Confirmer
        </UCAButton>
        {isLoading && <Loading />}
        {error.hasError && (
          <Box sx={{ maxWidth: "350px", mt: 3 }}>
            <Typography variant="overline" sx={styles.errorText}>
              {error.message}
            </Typography>
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default ResetPassword;
