import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { styles } from "./Register.style";

import { register, verifyRegisterData } from "../../../api/users/usersHandler";
import { getUserGroups } from "../../../api/usergroups/usergroupsHandler";

import UnexpectedError from "../../../components/errors/UnexpectedError";
import Loading from "../../../components/loading/Loading";
import { UCAButton } from "../../../utils/Styled";

import {
  Box,
  Card,
  TextField,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DoneIcon from "@mui/icons-material/Done";
import AbcIcon from "@mui/icons-material/Abc";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function Register() {
  const defaultErrorValues = {
    usernameText: "",
    hasUsernameError: false,
    fullnameText: "",
    hasFullnameError: false,
    emailText: "",
    hasEmailError: false,
    usergroupText: "",
    hasUsergroupError: false,
  };

  const defaultFormValues = {
    username: "",
    fullname: "",
    email: "",
    usergroup: "",
  };

  const navigateTo = useNavigate();

  const [userGroups, setUserGroups] = useState();

  const getServerData = useCallback(async () => {
    try {
      const aUserGroups = await getUserGroups();
      setUserGroups(aUserGroups);
    } catch {
      setHasPageError(true);
    }
  }, []);

  useEffect(() => {
    getServerData();
  }, [getServerData]);

  const [errorValues, setErrorValues] = useState(defaultErrorValues);
  const [formValues, setFormValues] = useState(defaultFormValues);

  const [hasPageError, setHasPageError] = useState(false);
  const [apiError, setApiError] = useState({
    hasError: false,
    text: "",
  });
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    setErrorValues(defaultErrorValues);
    setApiError({
      hasError: false,
      text: "",
    });
    const aErrorValues = verifyRegisterData(formValues);

    if (
      aErrorValues.hasEmailError ||
      aErrorValues.hasUsergroupError ||
      aErrorValues.hasFullnameError ||
      aErrorValues.hasUsernameError
    ) {
      setErrorValues(aErrorValues);
      setIsLoading(false);
      return;
    }

    try {
      await register(formValues);
      setIsLoading(false);
      setIsUserRegistered(true);
    } catch (error) {
      if (error.status === 409) {
        setApiError({
          hasError: true,
          text: "Un compte avec ce nom d'utilisateur existe déjà",
        });
      } else {
        setApiError({
          hasError: true,
          text: "Une erreur est survenue, veuillez réessayer.",
        });
      }
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const resetAll = () => {
    setFormValues(defaultFormValues);
    setErrorValues(defaultErrorValues);
    setApiError({
      hasError: false,
      text: "",
    });
    setIsUserRegistered(false);
  };

  if (hasPageError) {
    return <UnexpectedError />;
  }

  if (!userGroups) {
    return <Loading />;
  }

  return (
    <Box sx={styles.container}>
      <Card sx={styles.card}>
        {isUserRegistered ? (
          <>
            <DoneIcon sx={styles.icon} />
            <Typography variant="overline">
              L'utilisateur a été enregistré avec succès.
            </Typography>
            <Box sx={styles.flexSpace}>
              <UCAButton sx={styles.submitButton} onClick={resetAll}>
                Nouvelle inscription
              </UCAButton>
              <UCAButton
                sx={styles.submitButton}
                onClick={() => {
                  navigateTo("/");
                }}
              >
                Accueil
              </UCAButton>
            </Box>
          </>
        ) : (
          <>
            <PersonAddIcon sx={styles.icon} />
            <Typography variant="overline" sx={{ fontSize: "10px" }}>
              Inscription d'un nouvel utilisateur
            </Typography>
            <FormControl sx={{ my: 1 }}>
              <TextField
                sx={styles.field}
                name="fullname"
                type="text"
                value={formValues.fullname}
                error={errorValues.hasFullnameError}
                helperText={errorValues.fullnameText}
                onChange={handleInputChange}
                placeholder="Nom complet"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AbcIcon style={styles.icon} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={styles.field}
                name="username"
                type="text"
                value={formValues.username}
                error={errorValues.hasUsernameError}
                helperText={errorValues.usernameText}
                onChange={handleInputChange}
                placeholder="Nom d'utilisateur"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon style={styles.icon} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                sx={styles.field}
                name="email"
                type="text"
                value={formValues.email}
                error={errorValues.hasEmailError}
                helperText={errorValues.emailText}
                onChange={handleInputChange}
                placeholder="exemple@mail.com"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon style={styles.icon} />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl error={errorValues.hasUsergroupError}>
                <Select
                  labelId="quality-label"
                  sx={styles.field}
                  value={formValues.usergroup}
                  onChange={(e) =>
                    setFormValues({ ...formValues, usergroup: e.target.value })
                  }
                  defaultValue={""}
                  variant="outlined"
                  displayEmpty
                >
                  <MenuItem value={""} disabled>
                    Choisissez un rôle
                  </MenuItem>
                  {userGroups.map((aUserGroup) => {
                    return (
                      <MenuItem value={aUserGroup.ref} key={aUserGroup.ref}>
                        <Typography>{aUserGroup.name}</Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errorValues.usergroupText}</FormHelperText>
              </FormControl>
            </FormControl>
            <UCAButton sx={styles.submitButton} onClick={handleSubmit}>
              Inscrire
            </UCAButton>
            {isLoading && <Loading />}
            {apiError.hasError && (
              <Typography variant="overline" sx={styles.errorText}>
                {apiError.text}
              </Typography>
            )}
          </>
        )}
      </Card>
    </Box>
  );
}
