import { useState, useEffect, useMemo } from "react";

import { styles } from "./EditResource.style";
import { UCAButton } from "../../../../utils/Styled";

import { putResource } from "../../../../api/resources/resourcesHandler";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  Typography,
  IconButton,
  TextField,
  Chip,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const EditResource = ({ resource, setResources, album, isOpen, setIsOpen }) => {

  const defaultError = useMemo(() => ({
    hasError: false,
    message: "",
  }), []);
  const [error, setError] = useState(defaultError);
  const [description, setDescription] = useState("");
  const [model, setModel] = useState("");
  const [credit, setCredit] = useState("");
  const [hasChange, setHasChange] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");

  useEffect(() => {
    if (isOpen) {
      setDescription(resource.metadata && resource.metadata.Description ? resource.metadata.Description : "");
      setModel(resource.metadata && resource.metadata["Camera make / model"] ? resource.metadata["Camera make / model"] : "");
      setCredit(resource.metadata && resource.metadata.Credit ? resource.metadata.Credit : "");
      const keywords = resource.keywords ? resource.keywords : [];
      setTags(keywords);
      setError(defaultError);
    }
  }, [isOpen, resource, defaultError]);

  const addTag = (iTag) => {
    const newTag = iTag
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (newTag !== "" && !tags.includes(newTag)) {
      setTags((prevTags) => {
        const updatedTags = [...prevTags, newTag];
        updateResourceTags(updatedTags);
        return updatedTags;
      });
    }
    setTagInput("");
  };

  const removeTag = (tag) => {
    setTags((prevTags) => {
      const updatedTags = prevTags.filter((t) => t !== tag);
      updateResourceTags(updatedTags);
      return updatedTags;
    });
  };

  const updateResourceTags = async (updatedTags) => {
    setHasChange(true);
    try {
      const metadata = {
        description: description,
        model: model,
        credit: credit,
        keywords: updatedTags,
      };
      await putResource(resource.album, resource.ref, metadata);
      const newResource = {
        ...resource,
        metadata: {
          Description: description,
          "Camera make / model": model,
          Credit: credit,
        },
        keywords: updatedTags,
      };
      setResources((prevResources) => {
        const newResources = prevResources.map((r) => {
          if (r.ref === resource.ref) {
            return newResource;
          }
          return r;
        });
        return newResources;
      });
    } catch (err) {
      setError({
        hasError: true,
        message: "Une erreur est survenue, veuillez réessayer.",
      });
    }
  };

  const handleTagInputKeyPress = (event) => {
    if (event.charCode === 32 || event.charCode === 13) {
      addTag(event.target.value);
    }
  };

  const handleTagInputChange = (event) => {
    if (event.target.value !== " ") {
      setTagInput(event.target.value);
    }
  };

  const handleSave = async () => {
    try {
      setError(defaultError);
      const metadata = {
        description: description,
        model: model,
        credit: credit,
        keywords: tags,
      };
      await putResource(resource.album, resource.ref, metadata);
      const newResource = {
        ...resource,
        metadata: {
          Description: description,
          "Camera make / model": model,
          Credit: credit,
        },
        keywords: tags,
      };
      setResources((prevResources) => {
        const newResources = prevResources.map((r) => {
          if (r.ref === resource.ref) {
            return newResource;
          }
          return r;
        });
        return newResources;
      });
      setHasChange(false);
      setIsOpen(false);
    } catch (err) {
      setError({
        hasError: true,
        message: "Une erreur est survenue, veuillez réessayer.",
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <IconButton
        onClick={() => setIsOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={styles.container}>
        <DialogTitle>Édition {resource.ref}</DialogTitle>
        <DialogContent>
          <Box sx={styles.flexColumn}>
            <Box sx={styles.flexCenter}>
              <Typography variant="overline" sx={styles.subTitle}>
                Album -
              </Typography>
              <Typography>{album}</Typography>
            </Box>
            <Box sx={styles.flexCenter}>
              <Typography variant="overline" sx={styles.subTitle}>
                Date de création -
              </Typography>
              <Typography>
                {new Date(resource.creationDate).toLocaleDateString("fr")}
              </Typography>
            </Box>
            <Box sx={styles.flexCenter}>
              <Typography variant="overline" sx={styles.subTitle}>
                Type -
              </Typography>
              <Typography>{resource.type}</Typography>
            </Box>
            <Box sx={{ ...styles.flexCenter, mt: 2 }}>
              <TextField
                sx={styles.field}
                label="Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setHasChange(true);
                }}
              />
            </Box>
            <Box sx={styles.flexCenter}>
              <TextField
                sx={styles.field}
                label="Modèle de l'appareil photo"
                value={model}
                onChange={(e) => {
                  setModel(e.target.value);
                  setHasChange(true);
                }}
              />
            </Box>
            <Box sx={styles.flexColumn}>
              <TextField
                sx={styles.field}
                label="Crédit"
                value={credit}
                onChange={(e) => {
                  setCredit(e.target.value);
                  setHasChange(true);
                }}
              />
            </Box>
            <Box sx={styles.flexColumn}>
              <TextField
                label="Tags (mots clés)"
                placeholder="Écrivez vos tags ici"
                sx={styles.field}
                value={tagInput}
                onChange={(e) => {
                  handleTagInputChange(e);
                }}
                onKeyPress={handleTagInputKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => addTag(tagInput)}>
                        <AddCircleOutlineIcon sx={styles.icon} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={styles.tagsContainer}>
                {tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => removeTag(tag)}
                    sx={{ mr: 1, mb: 1 }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={styles.flexCenter}>
          <UCAButton
            onClick={handleSave}
            sx={styles.button}
            disabled={!hasChange}
          >
            Valider
          </UCAButton>
        </DialogActions>
        {error.hasError && (
          <Typography variant="overline" sx={styles.errorText}>
            {error.message}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default EditResource;
