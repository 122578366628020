export const styles = {
  menuItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "30px",
    height: "auto",
    padding: "16px",
    boxSizing: "border-box",
    overflow: "hidden",
    mx: 2,
    p: 3,
  },

  imageContainer: {
    width: "100%",
    paddingTop: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  preview: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "20px",
  },

  name: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "90%",
    textOverflow: "ellipsis",
    textAlign: "center",
  },

  navigationContainer: {
    display: "flex",
    alignItems: "center",
    mx: 2,
  },

  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    px: { xs: 0, md: 4 },
    py: { xs: 5, md: 2 },
  },

  flexEnd: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  
  sortBar: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: "20px",
    mx: 2,
    mb: 2,
    width: "300px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },
};
