import { styles } from "./NavMenu.style";
import logo from "../../assets/uca-white.png";

import { Box, AppBar, Toolbar, Link, Typography } from "@mui/material";
import PendingIcon from "@mui/icons-material/Pending";

export default function LoadingNavMenu() {
  return (
    <header style={{ marginBottom: "75px" }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={styles.appBar}>
          <Toolbar>
            <Link href={`/`} sx={styles.link}>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <img src={logo} width="230" alt="logo" />
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <img src={logo} width="160" alt="logo" />
              </Box>
            </Link>
            <Typography sx={{ flexGrow: 1 }} />
            <PendingIcon />
          </Toolbar>
        </AppBar>
      </Box>
    </header>
  );
}
