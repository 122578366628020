import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getUsers } from "../../../api/users/usersHandler";
import { getUserGroups } from "../../../api/usergroups/usergroupsHandler";

import { styles } from "./ManageUsers.style";

import UserRow from "./UserRow";
import Loading from "../../../components/loading/Loading";
import UnexpectedError from "../../../components/errors/UnexpectedError";
import { UCAButton } from "../../../utils/Styled";

import {
  Box,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  FormControl,
  TableBody,
  Pagination,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

const ManageUsers = () => {
  const navigateTo = useNavigate();
  const [searchResult, setSearchResult] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [usergroups, setUsergroups] = useState([]);

  const [users, setUsers] = useState();
  const [hasApiError, setHasApiError] = useState(false);

  const handleSearchChange = (iEvent) => {
    setSearchValue(iEvent.target.value);
    if (iEvent.target.value.length >= 2) {
      setPageNumber(1);
      setSearchResult(iEvent.target.value);
    } else {
      setSearchResult("");
    }
  };

  const handlePaginationChange = (iEvent, iValue) => {
    setPageNumber(iValue);
  };

  const fetchData = useCallback(async () => {
    try {
      const aResponse = await getUsers(pageNumber, 30, searchResult);
      setUsers(aResponse.users);
      setPageCount(aResponse.count);
      setTotalCount(aResponse.totalCount);
      if (!usergroups.length) {
        const aUsergroups = await getUserGroups();
        setUsergroups(aUsergroups);
      }
    } catch {
      setHasApiError(true);
    }
  }, [pageNumber, searchResult, usergroups.length]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (hasApiError) {
    return <UnexpectedError />;
  }

  if (!users || !usergroups.length) {
    return <Loading />;
  }

  const userRows = users.map((aUser) => {
    return (
      <UserRow
        key={aUser.ref}
        user={aUser}
        usergroups={usergroups}
        setUsers={setUsers}
      />
    );
  });

  return (
    <Box>
      <Typography variant="h2" sx={styles.title}>
        Gestion des utilisateurs
      </Typography>
      <UCAButton
        sx={styles.addButton}
        onClick={() => {
          navigateTo("/register");
        }}
      >
        <AddIcon sx={{ mr: 2 }} />
        <Typography>Ajouter un utilisateur</Typography>
      </UCAButton>
      <Box sx={{ mx: "4%" }}>
        <TableContainer component={Paper} sx={styles.tableContainer}>
          <Table sx={styles.headTable}>
            <TableHead size="medium" sx={styles.headTableHead}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <FormControl>
                    <TextField
                      id="search-input"
                      name="search"
                      placeholder="Rechercher un utilisateur..."
                      type="text"
                      variant="outlined"
                      value={searchValue}
                      onChange={handleSearchChange}
                      sx={styles.searchBar}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ color: "#027FA2" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Pagination
                    count={pageCount}
                    page={pageNumber}
                    onChange={handlePaginationChange}
                    sx={styles.pagination}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Table sx={{ borderTop: "1px solid rgba(99, 99, 99, 0.2)" }}>
            <TableHead>
              <TableRow>
                <TableCell component="th" align="center">
                  <strong>Identifiant</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Nom complet</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Nom d'utilisateur</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Adresse mail</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Groupe d'utilisateur</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Date de création</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{userRows}</TableBody>
          </Table>
          <Table>
            <TableHead sx={{ display: "flex", justifyContent: "start" }}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Typography>
                    <strong>Total d'utilisateurs :</strong> {totalCount}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead sx={{ display: "flex", justifyContent: "end" }}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Pagination
                    count={pageCount}
                    page={pageNumber}
                    onChange={handlePaginationChange}
                    sx={styles.pagination}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ManageUsers;
