import { styles } from "./AddResources.style";

import { Typography, TextField, Select, MenuItem } from "@mui/material";

const OldThemeBox = ({
  albumSelected,
  setAlbumSelected,
  albumName,
  setAlbumName,
  albums,
  isPublic,
  setIsPublic,
  renderTagInput,
  renderMetadata,
  renderFileUploader,
  renderSaveButton,
}) => {
  const SELECT_NEW_ALBUM = "newAlbum";
  return (
    <>
      <Select
        sx={styles.field}
        MenuProps={styles.menuProps}
        value={albumSelected}
        onChange={(e) => setAlbumSelected(e.target.value)}
        displayEmpty
        defaultValue={""}
      >
        <MenuItem value={""} disabled>
          Sélectionnez un album
        </MenuItem>
        <MenuItem value={SELECT_NEW_ALBUM}>
          <Typography>Nouvel album</Typography>
        </MenuItem>
        {albums.map((album, index) => (
          <MenuItem key={index} value={album.ref}>
            <Typography>{album.name}</Typography>
          </MenuItem>
        ))}
      </Select>
      {albumSelected && (
        <>
          {albumSelected === SELECT_NEW_ALBUM && (
            <>
              <TextField
                label="Nom de l'album"
                value={albumName}
                onChange={(e) => setAlbumName(e.target.value)}
                sx={styles.field}
              />
              <Select
                sx={styles.field}
                value={isPublic}
                MenuProps={styles.menuProps}
                onChange={(e) => setIsPublic(e.target.value)}
                displayEmpty
                defaultValue={""}
              >
                <MenuItem value={""} disabled>
                  Portée de l'album
                </MenuItem>
                <MenuItem value={0}>
                  <Typography>Privé</Typography>
                </MenuItem>
                <MenuItem value={1}>
                  <Typography>Public</Typography>
                </MenuItem>
              </Select>
            </>
          )}

          {renderTagInput()}
          {renderMetadata()}
          {renderFileUploader()}
          {renderSaveButton()}
        </>
      )}
    </>
  );
};

export default OldThemeBox;
