import { styles } from "./AddResources.style";

import { TextField } from "@mui/material";

const MetadataFields = ({
  description,
  setDescription,
  model,
  setModel,
  credit,
  setCredit,
}) => {
  return (
    <>
      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        sx={styles.field}
      />
      <TextField
        label="Modèle de l'appareil photo"
        value={model}
        onChange={(e) => setModel(e.target.value)}
        sx={styles.field}
      />
      <TextField
        label="Crédit"
        value={credit}
        onChange={(e) => setCredit(e.target.value)}
        sx={styles.field}
      />
    </>
  );
};

export default MetadataFields;
