export const styles = {
  card: {
    p: 4,
    my: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "20px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },

  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    p: 2,
  },

  submitButton: {
    mt: 2,
    width: "200px",
  },

  field: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    backgroundColor: "white",
    borderRadius: "20px",
    width: "300px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  errorText: {
    fontSize: "11px",
    color: "#C34D60",
    mt: 2,
  },

  icon: {
    color: "#027FA2",
  },
};
