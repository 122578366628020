import { styles } from "./AddResources.style";

import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Chip,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const TagInput = ({ tags, setTags, tagInput, setTagInput }) => {
  const addTag = (iTag) => {
    const newTag = iTag
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (newTag !== "" && !tags.includes(newTag)) {
      setTags((prevTags) => {
        return [...prevTags, newTag];
      });
    }
    setTagInput("");
  };

  const removeTag = (tag) => {
    setTags(tags.filter((t) => t !== tag));
  };

  const handleTagInputKeyPress = (event) => {
    if (event.charCode === 32 || event.charCode === 13) {
      addTag(event.target.value);
    }
  };

  const handleTagInputChange = (event) => {
    if (event.target.value !== " ") {
      setTagInput(event.target.value);
    }
  };
  return (
    <>
      <TextField
        label="Tags (mots clés)"
        placeholder="Écrivez vos tags ici"
        sx={styles.field}
        value={tagInput}
        required
        onChange={handleTagInputChange}
        onKeyPress={handleTagInputKeyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => addTag(tagInput)}>
                <AddCircleOutlineIcon sx={styles.icon} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={styles.tagsContainer}>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => removeTag(tag)}
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
      </Box>
    </>
  );
};

export default TagInput;
