import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Login from "./routes/login/Login";
import NavMenu from "./components/navMenu/NavMenu";
import Register from "./routes/admin/register/Register";
import { getUser } from "./api/users/usersHandler";
import { STORAGE_ACCESS_TOKEN, STORAGE_USER_ID } from "./utils/Constants";
import HomePage from "./routes/home/HomePage";
import ManageUsers from "./routes/admin/manageUsers/ManageUsers";
import ManageUsergroups from "./routes/admin/manageUsergroups/ManageUsergroups";
import NotFound from "./components/errors/NotFound";
import Contact from "./routes/contact/Contact";
import Themes from "./routes/photos/themes/Themes";
import Collections from "./routes/photos/collections/Collections";
import Resources from "./routes/photos/resources/display/Resources";
import SearchResources from "./routes/photos/resources/search/SearchResources";
import AddResources from "./routes/photos/resources/add/AddResources";
import ManageCollections from "./routes/admin/manageCollections/ManageCollections";
import ResetPassword from "./routes/password/ResetPassword";
import Loading from "./components/loading/Loading";
import LoadingNavMenu from "./components/navMenu/LoadingNavMenu";
import Recent from "./routes/photos/recent/Recent";
import ManageThemes from "./routes/admin/manageThemes/ManageThemes";
import { useKonamiCode } from "./utils/useKonamiCode";

function App() {
  const [user, setUser] = useState();
  const konamiCode = useKonamiCode();

  const fetchData = useCallback(async () => {
    try {
      const aResponse = await getUser(localStorage.getItem(STORAGE_USER_ID));
      if (aResponse && aResponse.user && aResponse.permissions) {
        setUser({
          ...aResponse.user,
          permissions: aResponse.permissions,
        });
      } else {
        throw new Error("User not authorized");
      }
    } catch {
      localStorage.removeItem(STORAGE_ACCESS_TOKEN);
      localStorage.removeItem(STORAGE_USER_ID);
      setUser();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!user && localStorage.getItem(STORAGE_ACCESS_TOKEN)) {
    return (
      <>
        <LoadingNavMenu />
        <Loading />
      </>
    );
  }

  return (
    <>
      <NavMenu user={user} setUser={setUser} konamiCode={konamiCode}/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage user={user} />} />
          {!user && (
            <Route
              path="/login"
              element={<Login user={user} setUser={setUser} />}
            />
          )}

          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/contact" element={<Contact />} />

          {user && (
            <>
              <Route path="/themes" element={<Themes />} />
              <Route path="/recent" element={<Recent user={user} />} />
              <Route path="/themes/:name" element={<Collections />} />
              <Route
                path="/themes/:name/:ref"
                element={<Resources user={user} />}
              />
              <Route path="/search" element={<SearchResources user={user} />} />
              {user.permissions.some((permission) => permission === "u") && (
                <>
                  <Route path="/register" element={<Register user={user} />} />
                  <Route path="/manage-users" element={<ManageUsers />} />
                </>
              )}

              {user.permissions.some((permission) => permission === "g") && (
                <Route
                  path="/manage-usergroups"
                  element={<ManageUsergroups />}
                />
              )}

              {user.permissions.some((permission) => permission === "r") && (
                <>
                  <Route
                    path="/manage-collections"
                    element={<ManageCollections />}
                  />
                  <Route path="/manage-themes" element={<ManageThemes />} />
                  <Route
                    path="/add-resources"
                    element={<AddResources user={user} />}
                  />
                </>
              )}
            </>
          )}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
