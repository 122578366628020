export const styles = {
  container: { p: 4 },

  pageTitle: { fontSize: "45px", textAlign: "center", m: 3 },

  uploadContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "30px",
    backgroundColor: "#F9F9F9",
    width: { xs: "95%", md: "90%", lg: "50%" },
    p: 3,
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  flexWrapCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },

  flexColumnCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    p: 3,
  },

  uploader: {
    p: 3,
    my: 3,
  },

  icon: {
    color: "#027FA2",
  },

  field: {
    display: "flex",
    justifyContent: "center",
    mt: 1,
    marginBottom: "20px",
    backgroundColor: "white",
    borderRadius: "20px",
    width: "400px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  card: {
    p: 4,
    m: 2,
    textAlign: "center",
    width: "300px",
    height: "200px",
    borderRadius: "20px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },

  photoTextContainer: {
    mb: 2,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  closeIcon: {
    position: "absolute",
    top: 20,
    right: 25,
    width: "30px",
    height: "30px",
    color: "#027FA2",
  },

  tagsContainer: {
    mt: 1,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
  },

  menuProps: {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 10,
        width: 250,
      },
    },
  },
};
