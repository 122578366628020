import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import Loading from "../../../components/loading/Loading";
import UnexpectedError from "../../../components/errors/UnexpectedError";
import SearchResourcesBar from "../resources/search/SearchResourcesBar";

import { styles } from "./Themes.style";

import {
  getThemesHash,
  getThemesWithPreview,
} from "../../../api/collections/collectionsHandler";
import { retrievePath } from "../../../utils/Utils";

import { Box, Grid, Typography, MenuItem } from "@mui/material";

const Themes = () => {
  const navigateTo = useNavigate();
  const [themes, setThemes] = useState();
  const [hasApiError, setHasApiError] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const cachedThemes = localStorage.getItem("themes");
      const cachedTimestamp = localStorage.getItem("themesTimestamp");
      const cachedHash = localStorage.getItem("themesHash");

      const currentTime = new Date().getTime();
      const oneWeek = 7 * 24 * 60 * 60 * 1000;

      const themesHashResponse = await getThemesHash();
      const themesHash = await themesHashResponse.hash;

      if (
        cachedThemes &&
        cachedTimestamp &&
        currentTime - cachedTimestamp < oneWeek &&
        themesHash === cachedHash
      ) {
        setThemes(JSON.parse(cachedThemes));
      } else {
        const aThemes = await getThemesWithPreview();
        aThemes.forEach((theme) => {
          if (theme.resource) {
            theme.image = retrievePath(theme.resource, "pre", "jpg");
          }
        });

        localStorage.setItem("themes", JSON.stringify(aThemes));
        localStorage.setItem("themesTimestamp", currentTime.toString());
        localStorage.setItem("themesHash", themesHash);
        setThemes(aThemes);
      }
    } catch (err) {
      setHasApiError(true);
    }
  }, []);

  useEffect(() => {
    fetchData();
    document.title = "Thèmes";
  }, [fetchData]);

  if (hasApiError) {
    return <UnexpectedError />;
  }

  if (!themes) {
    return (
      <Box sx={{ mt: "150px" }}>
        <Loading />
      </Box>
    );
  }

  return (
    <Box sx={{ my: 3 }}>
      <Box sx={styles.searchContainer}>
        <SearchResourcesBar />
      </Box>

      <Typography
        variant="h4"
        textAlign={"center"}
        sx={{ mb: { xs: 0, md: 2, lg: 3 }, mt: 2 }}
      >
        Thèmes
      </Typography>
      <Grid container spacing={4}>
        {themes.map((theme, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                opacity: { duration: 0.5, delay: index * 0.1 },
                y: { duration: 0.5, delay: index * 0.1 },
              }}
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.95 }}
              hovertransition={{ duration: 0.01 }}
            >
              <MenuItem
                sx={styles.menuItem}
                onClick={() =>
                  navigateTo(`/themes/${encodeURIComponent(theme.name)}`)
                }
              >
                <Box sx={styles.imageContainer}>
                  <img
                    src={theme.image}
                    alt={theme.name}
                    style={styles.preview}
                  />
                </Box>
                <Typography variant="overline">Thème</Typography>
                <Typography sx={styles.name}>{theme.name}</Typography>
              </MenuItem>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Themes;
