import { useState } from "react";

import { styles } from "./EditTheme.style";
import { UCAButton } from "../../../../utils/Styled";

import { putTheme } from "../../../../api/collections/collectionsHandler";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const EditTheme = ({ theme, setThemes, isOpen, setIsOpen }) => {
  const defaultError = {
    hasError: false,
    message: "",
  };
  const [error, setError] = useState(defaultError);
  const [name, setName] = useState(theme);

  const handleSave = async () => {
    setError(defaultError);
    if (name === "") {
      setError({ hasError: true, message: "Veuillez renseigner un nom." });
      return;
    }

    try {
      const data = {
        name: name,
      };

      await putTheme(encodeURIComponent(theme), data);

      setThemes((prevThemes) => {
        let newThemes = [...prevThemes];
        const index = newThemes.findIndex((th) => th === theme);
        newThemes[index] = name;
        return newThemes;
      });
      setIsOpen(false);
    } catch {
      setError({
        hasError: true,
        message: "Une erreur est survenue, veuillez réessayer.",
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <IconButton
        onClick={() => setIsOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={styles.container}>
        <DialogTitle>Édition de thème</DialogTitle>
        <DialogContent>
          <Box sx={styles.flexColumn}>
            <Box sx={{ ...styles.flexCenter, mt: 2 }}>
              <TextField
                sx={styles.field}
                label="Nom"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={styles.flexCenter}>
          <UCAButton
            onClick={handleSave}
            sx={styles.button}
            disabled={name === ""}
          >
            Valider
          </UCAButton>
        </DialogActions>
        {error.hasError && (
          <Typography variant="overline" sx={styles.errorText}>
            {error.message}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default EditTheme;
