import { Typography, Card, Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export default function UnexpectedError() {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          color: "#C34D60",
          width: { xs: "90%", md: "500px" },
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 5,
          borderRadius: "20px",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        }}
      >
        <CancelIcon sx={{ fontSize: "40px", mb: 3 }} />
        <Typography variant="overline">
          Une erreur est survenue, veuillez réessayer
        </Typography>
      </Card>
    </Box>
  );
}
