import { useState } from "react";

import { styles } from "./EditCollection.style";
import { UCAButton } from "../../../../utils/Styled";

import { putCollection } from "../../../../api/collections/collectionsHandler";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  Typography,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const EditCollection = ({ collection, setCollections, isOpen, setIsOpen }) => {
  const defaultError = {
    hasError: false,
    message: "",
  };
  const [error, setError] = useState(defaultError);
  const [isPublic, setIsPublic] = useState(collection.public);
  const [tags, setTags] = useState(collection.keywords);
  const [name, setName] = useState(collection.name);
  const [tagInput, setTagInput] = useState("");

  const addTag = (iTag) => {
    const newTag = iTag
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    if (newTag !== "" && !tags.includes(newTag)) {
      setTags((prevTags) => {
        return [...prevTags, newTag];
      });
    }
    setTagInput("");
  };

  const removeTag = (tag) => {
    setTags(tags.filter((t) => t !== tag));
  };

  const handleTagInputKeyPress = (event) => {
    if (event.charCode === 32 || event.charCode === 13) {
      addTag(event.target.value);
    }
  };

  const handleTagInputChange = (event) => {
    if (event.target.value !== " ") {
      setTagInput(event.target.value);
    }
  };

  const handleSave = async () => {
    setError(defaultError);
    if (name === "") {
      setError({ hasError: true, message: "Veuillez renseigner un nom." });
      return;
    }

    try {
      const data = {
        name: name,
        public: isPublic,
        keywords: tags,
      };
      const aUpdatedCollection = await putCollection(collection.ref, data);

      setCollections((prevCollections) => {
        const newCollections = [...prevCollections];
        const index = newCollections.findIndex(
          (collection) => collection.ref === aUpdatedCollection.ref
        );
        newCollections[index] = aUpdatedCollection;
        return newCollections;
      });
      setIsOpen(false);
    } catch {
      setError({
        hasError: true,
        message: "Une erreur est survenue, veuillez réessayer.",
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <IconButton
        onClick={() => setIsOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={styles.container}>
        <DialogTitle>Édition {collection.name}</DialogTitle>
        <DialogContent>
          <Box sx={styles.flexColumn}>
            <Box sx={styles.flexCenter}>
              <Typography variant="overline" sx={styles.subTitle}>
                Thème -
              </Typography>
              <Typography>{collection.theme}</Typography>
            </Box>
            <Box sx={styles.flexCenter}>
              <Typography variant="overline" sx={styles.subTitle}>
                Date de création -
              </Typography>
              <Typography>
                {new Date(collection.created).toLocaleDateString("fr")}
              </Typography>
            </Box>
            <Box sx={styles.flexCenter}>
              <Typography variant="overline" sx={styles.subTitle}>
                Utilisateur -
              </Typography>
              <Typography>{collection.user}</Typography>
            </Box>
            <Box sx={{ ...styles.flexCenter, mt: 2 }}>
              <TextField
                sx={styles.field}
                label="Nom"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
            <Box sx={styles.flexCenter}>
              <Select
                sx={styles.field}
                value={isPublic}
                MenuProps={styles.menuProps}
                onChange={(e) => setIsPublic(e.target.value)}
                displayEmpty
                defaultValue={""}
              >
                <MenuItem value={""} disabled>
                  Portée de l'album
                </MenuItem>
                <MenuItem value={0}>
                  <Typography>Privé</Typography>
                </MenuItem>
                <MenuItem value={1}>
                  <Typography>Public</Typography>
                </MenuItem>
              </Select>
            </Box>
            <Box sx={styles.flexColumn}>
              <TextField
                label="Tags (mots clés)"
                placeholder="Écrivez vos tags ici"
                sx={styles.field}
                value={tagInput}
                onChange={handleTagInputChange}
                onKeyPress={handleTagInputKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => addTag(tagInput)}>
                        <AddCircleOutlineIcon sx={styles.icon} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={styles.tagsContainer}>
                {tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    onDelete={() => removeTag(tag)}
                    sx={{ mr: 1, mb: 1 }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={styles.flexCenter}>
          <UCAButton
            onClick={handleSave}
            sx={styles.button}
            disabled={name === "" || tags.length === 0}
          >
            Valider
          </UCAButton>
        </DialogActions>
        {error.hasError && (
          <Typography variant="overline" sx={styles.errorText}>
            {error.message}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default EditCollection;
