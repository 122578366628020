import {
  Box,
  DialogContent,
  Dialog,
  IconButton,
  DialogTitle,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    py: 1,
    px: 3,
  },
  tagsContainer: {
    mt: 1,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    maxWidth: "300px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  permIcon: { fontSize: "14px", mr: 1 },
  permText: { fontSize: "16px" },
};

const KeywordsModal = ({ collection, isOpen, setIsOpen }) => {
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <IconButton
        onClick={() => setIsOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={styles.container}>
        <DialogTitle>Mots clés de l'album {collection.name}</DialogTitle>
        <DialogContent>
          <Box sx={styles.tagsContainer}>
            {collection.keywords.map((tag, index) => (
              <Chip key={index} label={tag} sx={{ mr: 1, mb: 1 }} />
            ))}
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default KeywordsModal;
