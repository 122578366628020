import api from "../../axios";

export const modifyPassword = async (iUserId, iData) => {
  const aResponse = await api.put(`/users/${iUserId}/password/modify`, iData);
  return aResponse.data;
};

export const resetPassword = async (iUserId, iData) => {
  const aResponse = await api.put(`/users/${iUserId}/password/reset`, iData);
  return aResponse.data;
};

export const postToken = async (iData) => {
  const aResponse = await api.post(`/tokens`, iData);
  return aResponse.data;
};

export const getToken = async (tokenId) => {
  const aResponse = await api.get(`/tokens/${tokenId}`);
  return aResponse.data;
};
