import { styles } from "./AddResources.style";
import { fileSizeToString, getFileType } from "../../../../utils/Utils";

import { Typography, Box, Card, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import ArticleIcon from "@mui/icons-material/Article";
import AudioFileIcon from "@mui/icons-material/AudioFile";

const PhotoCard = ({ photos, setPhotos }) => {
  const deletePhoto = (iIndex) => {
    const aPhoto = Array.from(photos);
    aPhoto.splice(iIndex, 1);
    setPhotos(aPhoto);
  };

  return (
    <>
      {photos.length !== 0 ? (
        <Box sx={styles.flexColumnCenter}>
          <Typography variant="overline">Photos importées</Typography>
          <Box sx={styles.flexWrapCenter}>
            {photos.map((aPhoto, aIndex) => {
              const aFileType = getFileType(aPhoto.name.split(".").pop());
              return (
                <Box position="relative" key={aIndex}>
                  <Card sx={styles.card}>
                    <IconButton
                      onClick={() => {
                        deletePhoto(aIndex);
                      }}
                      sx={styles.closeIcon}
                    >
                      <CloseIcon sx={styles.deleteCross} />
                    </IconButton>

                    <Box sx={styles.photoTextContainer}>
                      <Typography variant="overline">
                        {aPhoto.name} - {fileSizeToString(aPhoto.size)}
                      </Typography>
                    </Box>

                    <Box height="165px">
                      {aFileType === "Video" && (
                        <VideoFileIcon sx={{ fontSize: "150px" }} />
                      )}
                      {aFileType === "Photo" && (
                        <img
                          src={URL.createObjectURL(aPhoto)}
                          style={{ maxWidth: "230px", maxHeight: "150px" }}
                          alt={aPhoto.name}
                        />
                      )}
                      {aFileType === "Audio" && (
                        <AudioFileIcon sx={{ fontSize: "150px" }} />
                      )}
                      {aFileType === "Document" && (
                        <ArticleIcon sx={{ fontSize: "150px" }} />
                      )}
                    </Box>
                  </Card>
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default PhotoCard;
