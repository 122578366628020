import { useEffect, useCallback, useState } from "react";

import { retrievePath } from "../../../utils/Utils";
import { getRecentResources } from "../../../api/resources/resourcesHandler";

import Loading from "../../../components/loading/Loading";

import UnexpectedError from "../../../components/errors/UnexpectedError";
import SearchResourcesBar from "../resources/search/SearchResourcesBar";
import DisplayResources from "../resources/display/DisplayResources";

import { styles } from "../resources/display/Resources.style";
import "../resources/display/Resources.css";

import { Typography, Box, Pagination } from "@mui/material";

const Recent = ({ user }) => {
  const [hasApiError, setHasApiError] = useState(false);
  const [resources, setResources] = useState();
  const [sortValue, setSortValue] = useState(-1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      const aResponse = await getRecentResources(sortValue, pageNumber, 30);
      aResponse.resources.forEach((resource) => {
        resource.image = retrievePath(resource.ref, "pre", "jpg");
        resource.src = retrievePath(resource.ref, "scr", "jpg");
      });
      setResources(aResponse.resources);
      setPageCount(aResponse.pageCount);
      setTotalCount(aResponse.totalCount);
      document.title = `Récemment ajouté`;
    } catch {
      setHasApiError(true);
    }
  }, [pageNumber, sortValue]);

  const handlePaginationChange = (iEvent, iValue) => {
    setPageNumber(iValue);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (hasApiError) {
    return <UnexpectedError />;
  }

  if (!resources) {
    return (
      <Box sx={{ mt: 10 }}>
        <Loading />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={styles.searchContainer}>
        <Box />
        <SearchResourcesBar />
      </Box>
      <Typography variant="h4" textAlign={"center"} sx={{ mb: 5 }}>
        Récemment ajouté
      </Typography>
      {totalCount === 0 ? (
        <Typography variant="h6" textAlign={"center"}>
          Aucune ressource trouvée
        </Typography>
      ) : (
        <>
          <Box sx={styles.paginationContainer}>
            <Pagination
              count={pageCount}
              page={pageNumber}
              onChange={handlePaginationChange}
              sx={styles.pagination}
            />
          </Box>
          <DisplayResources
            user={user}
            resources={resources}
            setResources={setResources}
            sortValue={sortValue}
            setSortValue={setSortValue}
          />
          <Box sx={styles.paginationContainer}>
            <Pagination
              count={pageCount}
              page={pageNumber}
              onChange={handlePaginationChange}
              sx={styles.pagination}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Recent;
