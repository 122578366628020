import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";

import { styles } from "./Collections.style";

import { getThemeWithPreview } from "../../../api/collections/collectionsHandler";
import { retrievePath } from "../../../utils/Utils";

import Loading from "../../../components/loading/Loading";
import UnexpectedError from "../../../components/errors/UnexpectedError";
import SearchResourcesBar from "../resources/search/SearchResourcesBar";
import NotFound from "../../../components/errors/NotFound";

import { Typography, Box, Grid, MenuItem, Select } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Collections = () => {
  const navigateTo = useNavigate();
  const { name } = useParams();
  const [hasApiError, setHasApiError] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [noContent, setNoContent] = useState(false);
  const [collections, setCollections] = useState();
  const [sortValue, setSortValue] = useState(-1);

  const fetchData = useCallback(async () => {
    try {
      const aResponse = await getThemeWithPreview(encodeURIComponent(name), sortValue);
      if (aResponse.length === 0) {
        setNoContent(true);
        return;
      }
      aResponse.forEach((collection) => {
        if (collection.resource) {
          collection.image = retrievePath(collection.resource, "pre", "jpg");
        }
      });
      setCollections(aResponse);
    } catch (error) {
      if (error.status === 403) {
        setIsUnauthorized(true);
        return;
      } else {
        setHasApiError(true);
      }
    }
  }, [name, sortValue]);

  useEffect(() => {
    document.title = `Thème - ${name}`;
    fetchData();
  }, [fetchData, name]);

  if (hasApiError) {
    return <UnexpectedError />;
  }

  if (noContent || isUnauthorized) {
    return <NotFound />;
  }

  if (!collections) {
    return <Loading />;
  }

  return (
    <Box>
      <Box sx={styles.searchContainer}>
        <Box sx={styles.navigationContainer}>
          <Link to="/themes">
            <Typography variant="overline">Thèmes</Typography>
          </Link>
          <ArrowForwardIosIcon sx={{ fontSize: "12px", mx: 1 }} />
          <Typography variant="overline">{name}</Typography>
        </Box>
        <SearchResourcesBar />
      </Box>
      <Typography variant="h4" textAlign={"center"} sx={{ mb: 3 }}>
        Thème - {name}
      </Typography>
      <Box sx={styles.flexEnd}>
        <Select
          sx={styles.sortBar}
          value={sortValue}
          onChange={(e) => setSortValue(e.target.value)}
        >
          <MenuItem value={-1}>
            <Typography>Du plus récent au moins récent</Typography>
          </MenuItem>
          <MenuItem value={1}>
            <Typography>Du moins récent au plus récent</Typography>
          </MenuItem>
        </Select>
      </Box>
      <Grid container spacing={4}>
        {collections.map((collection, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                opacity: { duration: 0.5, delay: index * 0.1 },
                y: { duration: 0.5, delay: index * 0.1 },
              }}
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.95 }}
              hovertransition={{ duration: 0.01 }}
            >
              <MenuItem
                sx={styles.menuItem}
                onClick={() =>
                  navigateTo(
                    `/themes/${encodeURIComponent(name)}/${collection.ref}`
                  )
                }
              >
                <Box sx={styles.imageContainer}>
                  <img
                    src={collection.image}
                    alt={collection.name}
                    style={styles.preview}
                  />
                </Box>
                <Typography variant="overline">Album</Typography>
                <Typography sx={styles.name}>{collection.name}</Typography>
              </MenuItem>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Collections;
