import React, { useState } from "react";

import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import EditUsergroup from "./editUsergroup/EditUsergroup";
import DeleteUsergroup from "./deleteUsergroup/DeleteUsergroup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PermissionModal from "./PermissionModal";

const UsergroupRow = ({ usergroup, setUsergroups, themes }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell align="center">
          <strong>{usergroup.ref}</strong>
        </TableCell>
        <TableCell align="center">{usergroup.name}</TableCell>
        <TableCell align="center">
          <IconButton
            onClick={() => {
              setIsPermissionModalOpen(true);
            }}
          >
            <VisibilityIcon sx={{ color: "#027FA2" }} />
          </IconButton>
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <IconButton
              onClick={() => {
                setIsEditOpen(true);
              }}
            >
              <EditIcon sx={{ color: "#027FA2" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setIsDeleteOpen(true);
              }}
            >
              <DeleteForeverIcon sx={{ color: "#C34D60" }} />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <EditUsergroup
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        usergroup={usergroup}
        setUsergroups={setUsergroups}
        themes={themes}
      />
      <DeleteUsergroup
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
        usergroup={usergroup}
        setUsergroups={setUsergroups}
      />
      <PermissionModal
        isOpen={isPermissionModalOpen}
        setIsOpen={setIsPermissionModalOpen}
        usergroup={usergroup}
      />
    </>
  );
};

export default UsergroupRow;
