import api from "../axios";

export const getResources = async (iCollectionId, iSortValue) => {
  const aResponse = await api.get(
    `/collections/${iCollectionId}/resources?sort=${iSortValue}`
  );
  return aResponse.data;
};

export const getRecentResources = async (
  iSortValue,
  iPageNumber,
  iNumberPerPage
) => {
  const aResponse = await api.get(
    `/resources/recent?sort=${iSortValue}&pageNumber=${iPageNumber}&numberPerPage=${iNumberPerPage}`
  );
  return aResponse.data;
};

export const postResource = async (iCollectionId, iData) => {
  const aResponse = await api.post(
    `/collections/${iCollectionId}/resources`,
    iData,
    {
      timeout: 60 * 60 * 10000,
    }
  );
  return aResponse.data;
};

export const putResource = async (iCollectionId, iResourceId, iData) => {
  const aResponse = await api.put(
    `/collections/${iCollectionId}/resources/${iResourceId}`,
    iData
  );
  return aResponse.data;
};

export const deleteResource = async (iCollectionId, iResourceId) => {
  const aResponse = await api.delete(
    `/collections/${iCollectionId}/resources/${iResourceId}`
  );
  return aResponse.data;
};

export const searchResource = async (
  iPageNumber,
  iNumberPerPage,
  iSearch,
  iSortValue
) => {
  const aResponse = await api.get(
    `/search?pageNumber=${iPageNumber}&numberPerPage=${iNumberPerPage}&search=${iSearch}&sort=${iSortValue}`
  );
  return aResponse.data;
};
