import api from "../axios";

export const postUserGroup = async (iData) => {
  const aResponse = await api.post(`/usergroups`, iData);
  return aResponse.data;
};

export const getUserGroups = async () => {
  const aResponse = await api.get(`/usergroups`);
  return aResponse.data;
};

export const putUserGroup = async (iUserGroupId, iData) => {
  const aResponse = await api.put(`/usergroups/${iUserGroupId}`, iData);
  return aResponse.data;
};

export const deleteUsergroup = async (iUserGroupId) => {
  const aResponse = await api.delete(`/usergroups/${iUserGroupId}`);
  return aResponse.data;
};
