import { styled } from "@mui/system";
import Button, { buttonClasses } from "@mui/base/Button";

const UCAColors = {
  500: "#027FA2",
  600: "#128FB2",
  700: "#229FC2",
};

export const UCAButton = styled(Button)`
  font-size: 0.875rem;
  background-color: ${UCAColors[500]};
  padding: 12px 24px;
  border-radius: 50px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: ${UCAColors[600]};
  }
  &.${buttonClasses.active} {
    background-color: ${UCAColors[700]};
  }
  &.${buttonClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const RedColors = {
  500: "#C34D60",
  600: "#D35D70",
  700: "#E36D80",
};

export const RedButton = styled(Button)`
  font-size: 0.875rem;
  background-color: ${RedColors[500]};
  padding: 12px 24px;
  border-radius: 50px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: ${RedColors[600]};
  }
  &.${buttonClasses.active} {
    background-color: ${RedColors[700]};
  }
  &.${buttonClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }
  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const UploaderBox = styled("div")`
  display: block;
  margin: 0px auto;
  border: 3px inset rgba(30, 30, 30, 0.6);
  border-radius: 3%;
  font-family: "Roboto", cursive;
  text-align: center;
  &:hover {
    cursor: pointer;
    border: 3px inset rgba(160, 160, 160, 0.6);
  }
`;
