import { PERMISSION_DESCRIPTIONS } from "../../../utils/Constants";

import {
  Box,
  Typography,
  DialogContent,
  Dialog,
  IconButton,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    py: 1,
    px: 5,
  },
  permissions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  permText: { fontSize: "16px" },
};

const renderPermissionRow = (usergroup, permission, text) => (
  <Box sx={styles.flexCenter} key={`${usergroup}.${permission}`}>
    <Typography sx={styles.permText}>{text}</Typography>
  </Box>
);

const PermissionModal = ({ isOpen, setIsOpen, usergroup }) => {
  const permissionArray = usergroup.permissions.split(",");
  const permissionRow = permissionArray.map((permission) => {
    if (permission[0] === "t") {
      const themeText =
        permission[1] === "*"
          ? "Accès à tous les thèmes"
          : `Thème - ${permission.substring(1)}`;
      return renderPermissionRow(usergroup, permission, themeText);
    } else {
      return renderPermissionRow(
        usergroup,
        permission,
        PERMISSION_DESCRIPTIONS[permission]
      );
    }
  });

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <IconButton
        onClick={() => setIsOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={styles.container}>
        <DialogTitle>Permissions {usergroup.name}</DialogTitle>
        <DialogContent>
          <Box sx={styles.permissions}>{permissionRow}</Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default PermissionModal;
