import axios from "axios";
import { API_BASE_URL, STORAGE_ACCESS_TOKEN } from "../utils/Constants";
import ApiException from "./ApiException";

const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
});

api.interceptors.request.use(
  (config) => {
    const aToken = localStorage.getItem(STORAGE_ACCESS_TOKEN);

    if (aToken) {
      config.headers.Authorization = `Bearer ${aToken}`;
    } else {
      delete api.defaults.headers.common.Authorization;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    throw new ApiException({
      status: error?.response?.status,
      message: error?.response?.data?.error,
    });
  }
);

export default api;
