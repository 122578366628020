import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  InputAdornment,
  FormControl,
  List,
  ListItemText,
  Popper,
  ClickAwayListener,
  Paper,
  ListItemButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { styles } from "./Search.style";
import { getKeywords } from "../../../../api/collections/collectionsHandler";

const SearchResourcesBar = ({ setSearchParams, query }) => {
  const navigateTo = useNavigate();
  const [searchValue, setSearchValue] = useState(query ? query : "");
  const [suggestions, setSuggestions] = useState([]);
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const fetchKeywords = useCallback(async () => {
    try {
      const data = await getKeywords(searchValue);
      setSuggestions(data.keywords.slice(0, 10));
      if (data.keywords.length > 0 && data.keywords[0] !== searchValue) {
        setOpen(true);
      }
    } catch {
      setSuggestions([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.length >= 2) {
      fetchKeywords();
    } else {
      setOpen(false);
      setSuggestions([]);
    }
  }, [searchValue, fetchKeywords]);

  const handleSearchChange = (iEvent) => {
    setSearchValue(iEvent.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (setSearchParams) {
        setSearchParams({ q: searchValue });
        setOpen(false);
      } else {
        navigateTo(`/search?q=${searchValue}`);
      }
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <FormControl>
      <TextField
        id="search-input"
        name="search"
        placeholder="Rechercher des photos ..."
        type="text"
        variant="outlined"
        value={searchValue}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        sx={styles.searchBar}
        helperText="Séparez les mots-clés par des espaces"
        ref={anchorRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#027FA2" }} />
            </InputAdornment>
          ),
        }}
      />
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          sx={{ zIndex: 1000, width: "300px" }}
          placement="bottom-start"
        >
          <Paper>
            <List>
              {suggestions.map((suggestion, index) => (
                <ListItemButton
                  key={index}
                  onClick={() => {
                    if (setSearchParams) {
                      setSearchParams({ q: suggestion });
                      setSearchValue(suggestion);
                      setOpen(false);
                    } else {
                      navigateTo(`/search?q=${suggestion}`);
                    }
                  }}
                >
                  <ListItemText primary={suggestion} />
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </FormControl>
  );
};
export default SearchResourcesBar;
