export const styles = {
  title: {
    textAlign: "center",
    mt: 15,
    mb: 3,
    fontSize: "35px",
  },

  tableContainer: { mb: 5, borderRadius: "20px" },
  headTable: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },

  headTableHead: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    ml: 3,
  },

  searchBar: {
    my: 2,
    backgroundColor: "white",
    width: "80%",
    minWidth: "300px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  pagination: {
    mx: 2,
    mt: { xs: -1, md: 1, lg: 3 },
    ul: {
      [`& .MuiPaginationItem-root`]: {
        color: "#027FA2",
      },
    },
  },

  addButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    minWidth: "300px",
    my: 5,
    mx: "auto",
    py: 1,
    px: 2,
  },
};
