import api from "../axios";

export const getCollections = async (iPageNumber, iNumberPerPage, iSearch) => {
  const aResponse = await api.get(
    `/collections?pageNumber=${iPageNumber}&numberPerPage=${iNumberPerPage}&search=${iSearch}`
  );
  return aResponse.data;
};

export const postCollection = async (iData) => {
  const aResponse = await api.post(`/collections`, iData);
  return aResponse.data;
};

export const getCollection = async (iCollectionId) => {
  const aResponse = await api.get(`/collections/${iCollectionId}`);
  return aResponse.data;
};

export const putCollection = async (iCollectionId, iData) => {
  const aResponse = await api.put(`/collections/${iCollectionId}`, iData);
  return aResponse.data;
};

export const deleteCollection = async (iCollectionId) => {
  const aResponse = await api.delete(`/collections/${iCollectionId}`);
  return aResponse.data;
};

export const getThemesWithPreview = async () => {
  const aResponse = await api.get(`/themes?preview=true`);
  return aResponse.data;
};

export const getThemes = async () => {
  const aResponse = await api.get(`/themes`);
  return aResponse.data;
};

export const getThemesHash = async () => {
  const aResponse = await api.get(`/themes/hash`);
  return aResponse.data;
};

export const putTheme = async (theme, data) => {
  const aResponse = await api.put(`/themes/${theme}`, data);
  return aResponse.data;
};

export const getThemeWithPreview = async (iName, iSortValue) => {
  const aResponse = await api.get(
    `/themes/${iName}?preview=true&sort=${iSortValue}`
  );
  return aResponse.data;
};

export const getTheme = async (iName) => {
  const aResponse = await api.get(`/themes/${iName}`);
  return aResponse.data;
};

export const getKeywords = async (iInput) => {
  const aResponse = await api.get(`/keywords?input=${iInput}`);
  return aResponse.data;
};
