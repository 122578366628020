import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { styles } from "./Login.style";

import { UCAButton } from "../../utils/Styled";
import { STORAGE_ACCESS_TOKEN, STORAGE_USER_ID } from "../../utils/Constants";
import AskResetPwdModal from "./AskResetPwdModal";

import {
  Box,
  Card,
  TextField,
  InputAdornment,
  Typography,
  FormControl,
  Link,
  IconButton,
} from "@mui/material";
import Loading from "../../components/loading/Loading";
import PasswordIcon from "@mui/icons-material/Password";
import LoginIcon from "@mui/icons-material/Login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { verifyLoginData, login } from "../../api/users/usersHandler";

export default function Login({ user, setUser }) {
  document.title = "Connexion";
  const defaultErrorValues = {
    usernameText: "",
    hasUsernameError: false,
    passwordText: "",
    hasPasswordError: false,
  };

  const defaultFormValues = {
    username: "",
    password: "",
  };

  const navigateTo = useNavigate();

  const [errorValues, setErrorValues] = useState(defaultErrorValues);
  const [formValues, setFormValues] = useState(defaultFormValues);

  const [hasApiError, setHasApiError] = useState(false);
  const [isDataFalse, setIsDataFalse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    showPassword
      ? setPasswordInputType("password")
      : setPasswordInputType("text");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setErrorValues(defaultErrorValues);
    setIsDataFalse(false);
    setHasApiError(false);

    const aErrorValues = verifyLoginData(formValues);

    if (aErrorValues.hasUsernameError || aErrorValues.hasPasswordError) {
      setErrorValues(aErrorValues);
      setIsLoading(false);
      return;
    }

    try {
      const aResponse = await login(formValues);
      localStorage.setItem(STORAGE_ACCESS_TOKEN, aResponse.accessToken);
      localStorage.setItem(STORAGE_USER_ID, aResponse.user.ref);
      setUser({
        ...aResponse.user,
        permissions: aResponse.permissions,
      });
      navigateTo(`/themes`);
    } catch (aError) {
      if (aError?.status === 400) {
        setIsDataFalse(true);
      } else {
        setHasApiError(true);
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Card sx={styles.card}>
            <LoginIcon sx={{ ...styles.icon, mb: 1 }} />
            <Typography variant="overline" sx={{ fontSize: "10px" }}>
              Connexion
            </Typography>
            <FormControl sx={{ my: 1 }}>
              <form>
                <TextField
                  sx={styles.field}
                  name="username"
                  type="text"
                  value={formValues.username}
                  error={errorValues.hasUsernameError}
                  helperText={errorValues.usernameText}
                  onChange={handleInputChange}
                  placeholder="Nom d'utilisateur"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon style={styles.icon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  sx={styles.field}
                  name="password"
                  type={passwordInputType}
                  value={formValues.password}
                  error={errorValues.hasPasswordError}
                  helperText={errorValues.passwordText}
                  onChange={handleInputChange}
                  placeholder="*********"
                  variant="outlined"
                  autoComplete="on"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon style={styles.icon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? (
                            <VisibilityIcon sx={styles.icon} />
                          ) : (
                            <VisibilityOffIcon sx={styles.icon} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
              <Box sx={{ my: 2 }}>
                <Link
                  onClick={() => {
                    setIsPasswordModalOpen(true);
                  }}
                  sx={styles.link}
                >
                  <Typography sx={styles.information}>
                    Mot de passe oublié ?
                  </Typography>
                </Link>
                <Link
                  onClick={() => {
                    navigateTo("/contact");
                  }}
                  sx={styles.link}
                >
                  <Typography sx={styles.information}>
                    Pas encore de compte ?
                  </Typography>
                </Link>
              </Box>
            </FormControl>
            <UCAButton sx={styles.submitButton} onClick={handleSubmit}>
              Connexion
            </UCAButton>
            {isLoading ? <Loading /> : null}
            {hasApiError ? (
              <Typography variant="overline" sx={styles.errorText}>
                Une erreur est survenue, veuillez réessayer.
              </Typography>
            ) : null}
            {isDataFalse ? (
              <Typography variant="overline" sx={styles.errorText}>
                Nom d'utilisateur ou mot de passe incorrect.
              </Typography>
            ) : null}
          </Card>
        </motion.div>
      </Box>
      <AskResetPwdModal
        isOpen={isPasswordModalOpen}
        setIsOpen={setIsPasswordModalOpen}
      />
    </>
  );
}
