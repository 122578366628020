import { styles } from "./AddResources.style";

import {
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";

const NewThemeBox = ({
  themeName,
  setThemeName,
  albumName,
  setAlbumName,
  isPublic,
  setIsPublic,
  usergroups,
  selectedGroups,
  setSelectedGroups,
  renderTagInput,
  renderMetadata,
  renderFileUploader,
  renderSaveButton,
}) => {
  const handleGroupSelection = (usergroup) => {
    if (selectedGroups.includes(usergroup)) {
      setSelectedGroups((prevGroups) =>
        prevGroups.filter((group) => group !== usergroup)
      );
    } else {
      setSelectedGroups((prevGroups) => [...prevGroups, usergroup]);
    }
  };
  return (
    <>
      <TextField
        label="Nom du thème"
        value={themeName}
        onChange={(e) => setThemeName(e.target.value)}
        sx={styles.field}
      />

      <Box sx={{ my: 2 }}>
        <Typography sx={{ mb: 2 }}>
          Les groupes ayant accès à ce thème :
        </Typography>
        {usergroups
          .filter((x) => !x.permissions.includes("j*"))
          .map((usergroup, index) => {
            return (
              <Box sx={{ ...styles.flexCenter }} key={index}>
                <Checkbox
                  checked={selectedGroups.includes(usergroup)}
                  onChange={() => handleGroupSelection(usergroup)}
                />
                <Typography variant="overline">{usergroup.name}</Typography>
              </Box>
            );
          })}
      </Box>
      {themeName && (
        <>
          <TextField
            label="Nom de l'album"
            value={albumName}
            onChange={(e) => setAlbumName(e.target.value)}
            sx={styles.field}
          />
          <Select
            sx={styles.field}
            value={isPublic}
            MenuProps={styles.menuProps}
            onChange={(e) => setIsPublic(e.target.value)}
            displayEmpty
            defaultValue={""}
          >
            <MenuItem value={""} disabled>
              Portée de l'album
            </MenuItem>
            <MenuItem value={0}>
              <Typography>Privé</Typography>
            </MenuItem>
            <MenuItem value={1}>
              <Typography>Public</Typography>
            </MenuItem>
          </Select>
          {albumName && (
            <>
              {renderTagInput()}
              {renderMetadata()}
              {renderFileUploader()}
              {renderSaveButton()}
            </>
          )}
        </>
      )}
    </>
  );
};

export default NewThemeBox;
