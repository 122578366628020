import { styles } from "./NavMenu.style";
import logo from "../../assets/uca-white.png";
import logov2 from "../../assets/OIP.png";

import { STORAGE_ACCESS_TOKEN, STORAGE_USER_ID } from "../../utils/Constants";

import WideScreenMenu from "./wideScreen/WideScreenMenu";
import PhoneMenu from "./phone/PhoneMenu";

import { Box, AppBar, Toolbar, Link, Typography } from "@mui/material";

export default function NavMenu({ user, setUser, konamiCode }) {
  const logoFinal = konamiCode ? logov2 : logo;
  const logout = () => {
    localStorage.removeItem(STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_USER_ID);
    setUser();
    window.location.assign("https://phototheque.univ-cotedazur.fr/");
  };

  return (
    <header style={{ marginBottom: "75px" }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={styles.appBar}>
          <Toolbar>
            <Link href={`/`} sx={styles.link}>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <img src={logoFinal} width="230" alt="logo" />
              </Box>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <img src={logoFinal} width="160" alt="logo" />
              </Box>
            </Link>
            <Typography sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: "none", md: "none", lg: "flex" } }}>
              <WideScreenMenu user={user} logout={logout} />
            </Box>

            <Box sx={{ display: { xs: "flex", md: "flex", lg: "none" } }}>
              <PhoneMenu user={user} logout={logout} />
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </header>
  );
}
