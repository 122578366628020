import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import SearchResourcesBar from "./SearchResourcesBar";
import UnexpectedError from "../../../../components/errors/UnexpectedError";
import Loading from "../../../../components/loading/Loading";
import DisplayResources from "../display/DisplayResources";

import { searchResource } from "../../../../api/resources/resourcesHandler";
import { retrievePath } from "../../../../utils/Utils";

import { styles } from "./Search.style";

import { Box, Typography, Pagination } from "@mui/material";

const SearchResources = ({ user }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [resources, setResources] = useState();
  const [hasApiError, setHasApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortValue, setSortValue] = useState(-1);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const aQuery = searchParams.get("q");
      document.title = `Recherche : ${aQuery}`;
      const aResponse = await searchResource(pageNumber, 30, aQuery, sortValue);
      aResponse.resources.forEach((resource) => {
        resource.image = retrievePath(resource.ref, "pre", "jpg");
        resource.src = retrievePath(resource.ref, "scr", "jpg");
      });
      setResources(aResponse.resources);
      setPageCount(aResponse.pageCount);
      setTotalCount(aResponse.totalCount);
    } catch {
      setHasApiError(true);
    }
    setIsLoading(false);
  }, [searchParams, pageNumber, sortValue]);

  const handlePaginationChange = (iEvent, iValue) => {
    setPageNumber(iValue);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (hasApiError) {
    return <UnexpectedError />;
  }

  if (!resources) {
    return <Loading />;
  }

  return (
    <Box>
      <Box sx={styles.searchContainer}>
        <SearchResourcesBar
          setSearchParams={setSearchParams}
          query={searchParams.get("q")}
        />
      </Box>

      <Typography variant="h4" textAlign={"center"}>
        Recherche
        <br />
        <Typography variant="overline">
          {totalCount} résultat(s) trouvé(s)
        </Typography>
      </Typography>

      {isLoading && <Loading />}

      <Box sx={{ display: isLoading ? "none" : "block" }}>
        {totalCount === 0 ? (
          <Typography variant="h6" textAlign={"center"}>
            Aucun résultat ne correspond à votre recherche
          </Typography>
        ) : (
          <>
            <Box sx={styles.paginationContainer}>
              <Pagination
                count={pageCount}
                page={pageNumber}
                onChange={handlePaginationChange}
                sx={styles.pagination}
              />
            </Box>
            <DisplayResources
              user={user}
              resources={resources}
              setResources={setResources}
              sortValue={sortValue}
              setSortValue={setSortValue}
            />
            <Box sx={styles.paginationContainer}>
              <Pagination
                count={pageCount}
                page={pageNumber}
                onChange={handlePaginationChange}
                sx={styles.pagination}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SearchResources;
