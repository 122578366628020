export const styles = {
  menuItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "30px",
    height: "auto",
    padding: "16px",
    boxSizing: "border-box",
    overflow: "hidden",
    mx: 2,
    p: 3,
  },

  imageContainer: {
    width: "100%",
    paddingTop: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  preview: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "20px",
  },

  name: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "90%",
    textOverflow: "ellipsis",
    textAlign: "center",
  },

  searchContainer: {
    display: "flex",
    justifyContent: { xs: "center", md: "end" },
    alignItems: "center",
    px: { xs: 0, md: 4 },
    py: { xs: 0, md: 2 },
  },
};
