import { useState, useEffect } from "react";
import Masonry from "react-masonry-css";
import {
  formatBytes,
  handleDownloads,
  isSameResource,
} from "../../../../utils/Utils";
import { RESOURCES_GROUPED_ACTIONS } from "../../../../utils/Constants";
import { RedButton, UCAButton } from "../../../../utils/Styled";
import { deleteResource } from "../../../../api/resources/resourcesHandler";
import ResourceItem from "./ResourceItem";
import MetadataDisplay from "./MetadataDisplay";
import { styles } from "./Resources.style";
import "./Resources.css";
import {
  Typography,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const DisplayResources = ({
  user,
  resources,
  setResources,
  album,
  sortValue,
  setSortValue,
}) => {
  const [selectedResources, setSelectedResources] = useState(new Set());
  const [previewImage, setPreviewImage] = useState(null);
  const [isResourceHovered, setIsResourceHovered] = useState(false);
  const [isPreviewHovered, setIsPreviewHovered] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [quality, setQuality] = useState("high");
  const [isDownloading, setIsDownloading] = useState(false);
  const [groupedAction, setGroupedAction] = useState("");
  const [isSuppressing, setIsSuppressing] = useState(false);
  const [lastSelectedResourceIndex, setLastSelectedResourceIndex] =
    useState(null);

  const toggleSelectedResource = (resource, index) => {
    const newSelectedResources = new Set(selectedResources);

    if (window.event.shiftKey && lastSelectedResourceIndex !== null) {
      const start = Math.min(lastSelectedResourceIndex, index);
      const end = Math.max(lastSelectedResourceIndex, index);

      for (let i = start; i <= end; i++) {
        if (
          !Array.from(newSelectedResources).find((r) =>
            isSameResource(r, resources[i])
          )
        ) {
          newSelectedResources.add(resources[i]);
        }
      }
    } else {
      const existingResource = Array.from(newSelectedResources).find((r) =>
        isSameResource(r, resource)
      );

      if (existingResource) {
        newSelectedResources.delete(existingResource);
      } else {
        newSelectedResources.add(resource);
      }
      setLastSelectedResourceIndex(index);
    }

    setSelectedResources(newSelectedResources);
  };

  const showEnlargedImage = (imageUrl) => {
    setEnlargedImage(imageUrl);
  };

  const closeEnlargedImage = () => {
    setEnlargedImage(null);
  };

  const navigateToNextImage = () => {
    const currentIndex = resources.findIndex((r) => r.src === enlargedImage);
    const nextIndex = (currentIndex + 1) % resources.length;
    setEnlargedImage(resources[nextIndex].src);
  };

  const navigateToPrevImage = () => {
    const currentIndex = resources.findIndex((r) => r.src === enlargedImage);
    const prevIndex = (currentIndex - 1 + resources.length) % resources.length;
    setEnlargedImage(resources[prevIndex].src);
  };

  const handleDelete = async () => {
    setIsSuppressing(true);
    try {
      for (const resource of selectedResources) {
        await deleteResource(resource.album, resource.ref);
      }
      const newResources = resources.filter((r) => !selectedResources.has(r));
      setResources(newResources);
      setSelectedResources(new Set());
      setGroupedAction("");
    } catch {
      alert("Une erreur est survenue lors de la suppression des ressources");
    }

    setIsSuppressing(false);
  };

  const downloadResources = async () => {
    setIsDownloading(true);
    await handleDownloads(selectedResources, quality);
    setSelectedResources(new Set());
    setIsDownloading(false);
  };

  const totalSize = Array.from(selectedResources).reduce(
    (sum, resource) => sum + resource.size,
    0
  );

  useEffect(() => {
    if (!isResourceHovered && !isPreviewHovered) {
      setPreviewImage(null);
    }
  }, [isResourceHovered, isPreviewHovered]);

  return (
    <Box>
      {selectedResources.size > 0 && (
        <Box sx={styles.downloadContainer}>
          <Select
            sx={{ ...styles.field, mt: { xs: 0, md: 2 }, mr: { xs: 0, md: 3 } }}
            value={groupedAction}
            displayEmpty
            variant="outlined"
            onChange={(e) => setGroupedAction(e.target.value)}
          >
            <MenuItem value="">
              <Typography>Actions groupées</Typography>
            </MenuItem>
            {user.permissions.some((permission) => permission === "r") && (
              <MenuItem value={RESOURCES_GROUPED_ACTIONS.DELETE}>
                <Typography>Suppression</Typography>
              </MenuItem>
            )}
            <MenuItem value={RESOURCES_GROUPED_ACTIONS.DOWNLOAD}>
              <Typography>Téléchargement</Typography>
            </MenuItem>
          </Select>
          {groupedAction === RESOURCES_GROUPED_ACTIONS.DELETE && (
            <>
              <RedButton onClick={handleDelete}>Supprimer</RedButton>
              {isSuppressing && (
                <Typography
                  variant="overline"
                  sx={{ ml: { xs: 0, md: 0, lg: 3 } }}
                >
                  Suppression en cours ...
                </Typography>
              )}
            </>
          )}
          {groupedAction === RESOURCES_GROUPED_ACTIONS.DOWNLOAD && (
            <>
              {!Array.from(selectedResources).some(
                (r) =>
                  r.type === "Video" ||
                  r.type === "Audio" ||
                  r.type === "Document"
              ) && (
                <Select
                  sx={{ ...styles.field, mt: 2, mr: { xs: 0, md: 2, lg: 3 } }}
                  value={quality}
                  onChange={(e) => setQuality(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value={"scr"}>
                    <Typography>
                      Impression basse ≈ {formatBytes(totalSize / 18)}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"lpr"}>
                    <Typography>
                      Web-écran ≈ {formatBytes(totalSize / 8)}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"high"}>
                    <Typography>
                      Fichier d'origine ≈ {formatBytes(totalSize)}
                    </Typography>
                  </MenuItem>
                </Select>
              )}
              <UCAButton
                sx={{ mr: { xs: 0, md: 2, lg: 3 } }}
                onClick={downloadResources}
              >
                Télécharger
              </UCAButton>
              {isDownloading && (
                <Typography
                  variant="overline"
                  sx={{ ml: { xs: 0, md: 0, lg: 3 } }}
                >
                  Téléchargement en cours ...
                </Typography>
              )}
            </>
          )}
          <IconButton
            onClick={() => setSelectedResources(new Set())}
            sx={{
              ml: { xs: 0, md: 0, lg: 2 },
              position: { xs: "absolute", md: "relative" },
              top: { xs: 10, md: 0 },
              right: { xs: 15, md: 0 },
              zIndex: 1,
            }}
          >
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
      )}
      <Box sx={styles.flexEnd}>
        <Select
          sx={styles.sortBar}
          value={sortValue}
          onChange={(e) => setSortValue(e.target.value)}
        >
          <MenuItem value={-1}>
            <Typography>Du plus récent au moins récent</Typography>
          </MenuItem>
          <MenuItem value={1}>
            <Typography>Du moins récent au plus récent</Typography>
          </MenuItem>
        </Select>
      </Box>
      <Masonry
        breakpointCols={{
          default: 6,
          1100: 4,
          700: 3,
          500: 2,
        }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {resources.map((resource, index) => (
          <ResourceItem
            user={user}
            resource={resource}
            setResources={setResources}
            album={album ? album : resource.albumName}
            index={index}
            selectedResources={selectedResources}
            toggleSelectedResource={toggleSelectedResource}
            showEnlargedImage={showEnlargedImage}
            setPreviewImage={setPreviewImage}
            setIsResourceHovered={setIsResourceHovered}
            key={index}
          />
        ))}
      </Masonry>
      {previewImage && (
        <Box
          sx={styles.previewImageContainer}
          onMouseEnter={() => setIsPreviewHovered(true)}
          onMouseLeave={() => setIsPreviewHovered(false)}
        >
          <img src={previewImage} alt="Preview" style={styles.previewImage} />
        </Box>
      )}
      <Dialog open={!!enlargedImage} onClose={closeEnlargedImage} maxWidth="xl">
        <DialogContent sx={styles.enlargeContainer}>
          <IconButton
            onClick={closeEnlargedImage}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            onClick={navigateToPrevImage}
            sx={styles.enlargeArrowBackwardButton}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton
            edge="end"
            color="inherit"
            onClick={navigateToNextImage}
            sx={styles.enlargeArrowForwardButton}
          >
            <ArrowForwardIosIcon />
          </IconButton>
          <Box sx={{ position: "relative" }}>
            {enlargedImage && (
              <>
                <Box sx={{ display: { xs: "none", md: "flex" } }}>
                  <img src={enlargedImage} alt="Enlarged" style={styles.enlarge} />
                </Box>
                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <img src={enlargedImage} alt="Enlarged" style={styles.phoneEnlarge} />
                </Box>
              </>
            )}
          </Box>
          {enlargedImage && (
            <MetadataDisplay
              resource={resources.find((r) => r.src === enlargedImage)}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DisplayResources;
