import { useNavigate } from "react-router-dom";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    py: 1,
    px: 3,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const ContactSent = ({ isOpen, setIsOpen }) => {
  const navigateTo = useNavigate();
  const handleClose = () => {
    setIsOpen(false);
    navigateTo("/");
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={styles.container}>
        <DialogTitle sx={styles.flexCenter}>
          <DoneIcon sx={{ color: "#027FA2", mr: 2 }} />
          <Typography>Message envoyé</Typography>
        </DialogTitle>
        <DialogContent>
          Votre demande a été prise en compte. Vous recevrez une réponse dès que
          possible.
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ContactSent;
