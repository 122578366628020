import api from "../axios";

export const getUsers = async (iPageNumber, iNumberPerPage, iSearch) => {
  const aReponse = await api.get(
    `/users?pageNumber=${iPageNumber}&numberPerPage=${iNumberPerPage}&search=${iSearch}`
  );
  return aReponse.data;
};

export const register = async (iData) => {
  const aResponse = await api.post("/users", iData);
  return aResponse.data;
};

export const verifyRegisterData = (iFormValues) => {
  let aErrorValues = {
    usernameText: "",
    hasUsernameError: false,
    fullnameText: "",
    hasFullnameError: false,
    emailText: "",
    hasEmailError: false,
    usergroupText: "",
    hasUsergroupError: false,
  };

  if (!iFormValues.username) {
    aErrorValues.usernameText = "Champ requis";
    aErrorValues.hasUsernameError = true;
  }

  if (!iFormValues.fullname) {
    aErrorValues.fullnameText = "Champ requis";
    aErrorValues.hasFullnameError = true;
  }

  if (!iFormValues.usergroup) {
    aErrorValues.usergroupText = "Champ requis";
    aErrorValues.hasUsergroupError = true;
  }

  const aRegexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!aRegexEmail.test(iFormValues.email)) {
    aErrorValues.emailText = "Adresse mail invalide";
    aErrorValues.hasEmailError = true;
  }

  return aErrorValues;
};

export const login = async (iData) => {
  const aResponse = await api.post("/users/authenticate", iData);
  return aResponse.data;
};

export const verifyLoginData = (iFormValues) => {
  let aErrorValues = {
    usernameText: "",
    hasUsernameError: false,
    passwordText: "",
    hasPasswordError: false,
  };

  if (!iFormValues.username) {
    aErrorValues.usernameText = "Champ requis";
    aErrorValues.hasUsernameError = true;
  }

  if (!iFormValues.password) {
    aErrorValues.passwordText = "Champ requis";
    aErrorValues.hasPasswordError = true;
  }

  return aErrorValues;
};

export const getUser = async (iUserId) => {
  const aResponse = await api.get(`/users/${iUserId}`);
  return aResponse.data;
};

export const putUser = async (iUserId, iData) => {
  const aResponse = await api.put(`/users/${iUserId}`, iData);
  return aResponse.data;
};

export const deleteUser = async (iUserId) => {
  const aResponse = await api.delete(`/users/${iUserId}`);
  return aResponse.data;
};
