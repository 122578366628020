import { useEffect, useCallback, useState } from "react";
import { useParams, Link } from "react-router-dom";

import { getResources } from "../../../../api/resources/resourcesHandler";
import { getCollection } from "../../../../api/collections/collectionsHandler";
import { retrievePath } from "../../../../utils/Utils";

import Loading from "../../../../components/loading/Loading";

import UnexpectedError from "../../../../components/errors/UnexpectedError";
import SearchResourcesBar from "../search/SearchResourcesBar";

import { styles } from "./Resources.style";
import "./Resources.css";

import { Typography, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DisplayResources from "./DisplayResources";

const Resources = ({ user }) => {
  const { name, ref } = useParams();
  const [hasApiError, setHasApiError] = useState(false);
  const [resources, setResources] = useState();
  const [collection, setCollection] = useState();
  const [sortValue, setSortValue] = useState(-1);

  const fetchData = useCallback(async () => {
    try {
      const aResources = await getResources(ref, sortValue);
      aResources.forEach((resource) => {
        resource.image = retrievePath(resource.ref, "pre", "jpg");
        resource.src = retrievePath(resource.ref, "scr", "jpg");
      });
      setResources(aResources);
      const aCollection = await getCollection(ref);

      setCollection(aCollection);
      document.title = `Album - ${aCollection.name}`;
    } catch {
      setHasApiError(true);
    }
  }, [ref, sortValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData, name]);

  if (hasApiError) {
    return <UnexpectedError />;
  }

  if (!resources || !collection) {
    return (
      <Box sx={{ mt: 10 }}>
        <Loading />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={styles.searchContainer}>
        <Box sx={styles.navigationContainer}>
          <Link to="/themes">
            <Typography variant="overline">Thèmes</Typography>
          </Link>
          <ArrowForwardIosIcon sx={{ fontSize: "12px", mx: 1 }} />
          <Link to={`/themes/${encodeURIComponent(name)}`}>
            <Typography variant="overline">{name}</Typography>
          </Link>
          <ArrowForwardIosIcon sx={{ fontSize: "12px", mx: 1 }} />
          <Typography variant="overline">{collection.name}</Typography>
        </Box>
        <SearchResourcesBar />
      </Box>
      <Typography variant="h4" textAlign={"center"} sx={{ mb: 5 }}>
        Album - {collection.name}
      </Typography>
      <DisplayResources
        user={user}
        resources={resources}
        setResources={setResources}
        album={collection.name}
        sortValue={sortValue}
        setSortValue={setSortValue}
      />
    </Box>
  );
};

export default Resources;
