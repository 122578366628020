import { useCallback, useEffect, useState } from "react";

import { UCAButton } from "../../../../utils/Styled";
import { styles } from "./EditUsergroup.style";

import { PERMISSION_DESCRIPTIONS } from "../../../../utils/Constants";

import Loading from "../../../../components/loading/Loading";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  DialogContent,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { putUserGroup } from "../../../../api/usergroups/usergroupsHandler";

const EditUsergroup = ({
  isOpen,
  setIsOpen,
  usergroup,
  setUsergroups,
  themes,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState({
    hasError: false,
    text: "",
  });
  const [permissions, setPermissions] = useState({});
  const [themeMode, setThemeMode] = useState("all");

  const initializePermissions = useCallback(() => {
    const newPermissions = {};
    const permissionList = usergroup.permissions.split(",");

    Object.keys(PERMISSION_DESCRIPTIONS).forEach((key) => {
      newPermissions[key] = permissionList.includes(key);
    });

    themes.forEach((theme) => {
      const themeKey = "t" + theme;
      newPermissions[themeKey] = permissionList.includes(themeKey);
    });

    if (permissionList.includes("t*")) {
      setThemeMode("all");
      newPermissions["t*"] = true;
    } else {
      setThemeMode("specific");
    }

    setPermissions(newPermissions);
  }, [usergroup.permissions, themes]);

  useEffect(() => {
    initializePermissions();
  }, [initializePermissions]);

  const handleCheckboxChange = (event) => {
    setPermissions({
      ...permissions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleThemeModeChange = (event) => {
    setThemeMode(event.target.value);
    if (event.target.value === "all") {
      setPermissions({ ...permissions, "t*": true });
    } else {
      setPermissions({ ...permissions, "t*": false });
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    setApiError({
      hasError: false,
      text: "",
    });

    let updatedPermissions = "";

    if (permissions["t*"]) {
      updatedPermissions = Object.entries(permissions)
        .filter(([key, isChecked]) => isChecked && !key.startsWith("t"))
        .map(([key]) => key)
        .join(",");

      updatedPermissions += ",t*";
    } else {
      updatedPermissions = Object.entries(permissions)
        .filter(([, isChecked]) => isChecked)
        .map(([key]) => key)
        .join(",");
    }

    try {
      await putUserGroup(usergroup.ref, {
        name: usergroup.name,
        permissions: updatedPermissions,
      });
      setUsergroups((prevUsergroups) =>
        prevUsergroups.map((iUsergroup) => {
          if (iUsergroup.ref === usergroup.ref) {
            return {
              ...iUsergroup,
              permissions: updatedPermissions,
            };
          }
          return iUsergroup;
        })
      );

      setIsLoading(false);
      setIsOpen(false);
    } catch {
      setApiError({
        hasError: true,
        text: "Une erreur est survenue, veuillez réessayer",
      });

      setIsLoading(false);
    }
  };

  const handleClose = () => {
    initializePermissions();
    setIsOpen(false);
  };

  const renderCheckboxes = () => {
    return Object.entries(PERMISSION_DESCRIPTIONS).map(([key, description]) => (
      <Box key={key}>
        <FormControlLabel
          control={
            <Checkbox
              checked={permissions[key] || false}
              onChange={handleCheckboxChange}
              name={key}
            />
          }
          label={description}
        />
        <br />
      </Box>
    ));
  };

  const renderThemeCheckboxes = () => {
    if (themeMode === "specific") {
      return themes.map((theme) => (
        <Box key={theme} sx={{ mt: "5px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions["t" + theme] || false}
                onChange={handleCheckboxChange}
                name={`t${theme}`}
              />
            }
            label={theme}
          />
          <br />
        </Box>
      ));
    }
    return null;
  };

  return (
    <Dialog open={isOpen} onClose={() => handleClose()}>
      <IconButton
        onClick={() => handleClose()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={styles.container}>
        <DialogTitle>Modification de {usergroup.name}</DialogTitle>
        <DialogContent>
          <Box sx={styles.permissionsContainer}>
            {renderCheckboxes()}
            <Select
              value={themeMode}
              onChange={handleThemeModeChange}
              sx={styles.field}
            >
              <MenuItem value={"all"}>Tous les thèmes</MenuItem>
              <MenuItem value={"specific"}>Certains thèmes</MenuItem>
            </Select>
            {renderThemeCheckboxes()}
          </Box>
        </DialogContent>
        <DialogActions>
          <UCAButton sx={styles.submitButton} onClick={handleSave}>
            Valider
          </UCAButton>
        </DialogActions>
        {isLoading && <Loading />}
        {apiError.hasError && (
          <Typography variant="overline" sx={styles.errorText}>
            {apiError.text}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default EditUsergroup;
