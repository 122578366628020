import { useState, useRef, useCallback } from "react";
import { motion } from "framer-motion";
import { isSameResource } from "../../../../utils/Utils";
import { styles } from "./Resources.style";
import { Box, Grid, IconButton } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import EditIcon from "@mui/icons-material/Edit";
import EditResource from "../edit/EditResource";

const ResourceItem = ({
  user,
  resource,
  setResources,
  album,
  index,
  selectedResources,
  toggleSelectedResource,
  showEnlargedImage,
  setPreviewImage,
  setIsResourceHovered
}) => {
  const [showIcons, setShowIcons] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const hoverTimeoutRef = useRef(null);
  const nbrsDeTimers = useRef(0);

  const handleMouseEnter = () => {
    setShowIcons(true);
    setIsResourceHovered(true);
    clearTimeout(hoverTimeoutRef.current);
    hoverTimeoutRef.current = setTimeout(() => {
      nbrsDeTimers.current++;
      setPreviewImage(resource.image);
    }, 1200);
  };

  const handleMouseLeave = () => {
    setShowIcons(false);
    setIsResourceHovered(false);
    clearTimeout(hoverTimeoutRef.current);
    nbrsDeTimers.current--;
  };

  const handleZoomClick = (event, imageUrl) => {
    event.stopPropagation();
    showEnlargedImage(imageUrl);
  };

  const handleClick = (event) => {
    toggleSelectedResource(resource, index);
  };

  const IconButtonSelect = useCallback(() => {
    return (
      <IconButton style={styles.selectIcon}>
        {Array.from(selectedResources).find((r) =>
          isSameResource(r, resource)
        ) ? (
          <CheckBoxIcon sx={{ fontSize: "16px" }} />
        ) : (
          <CheckBoxOutlineBlankIcon sx={{ fontSize: "16px" }} />
        )}
      </IconButton>
    );
  }, [selectedResources, resource]);

  return (
    <Grid item xs={6} md={4} lg={2} key={index}>
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          opacity: { duration: 0.5, delay: index * 0.05 },
          y: { duration: 0.5, delay: index * 0.05 },
        }}
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 0.95 }}
        hovertransition={{ duration: 0.01 }}
      >
        <Box
          style={styles.imageContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        >
          {<IconButtonSelect />}

          {showIcons && (
            <>
              {user.permissions.some((permission) => permission === "r") && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditOpen(true);
                  }}
                  style={styles.settingsIcon}
                >
                  <EditIcon sx={{ fontSize: "35px" }} />
                </IconButton>
              )}
              <IconButton
                onClick={(event) => handleZoomClick(event, resource.src)}
                style={styles.zoomIcon}
              >
                <ZoomInIcon sx={{ fontSize: "35px" }} />
              </IconButton>
            </>
          )}

          <img src={resource.image} alt={resource.ref} style={styles.preview} />
        </Box>
      </motion.div>
      <EditResource
        resource={resource}
        setResources={setResources}
        album={album}
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
      />
    </Grid>
  );
};

export default ResourceItem;
