import { useState } from "react";

import { styles } from "../NavMenu.style";

import { Link, Typography, MenuItem, Menu, Divider, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PhotoIcon from "@mui/icons-material/Photo";
import LoginIcon from "@mui/icons-material/Login";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import LogoutIcon from "@mui/icons-material/Logout";
import HistoryIcon from "@mui/icons-material/History";

const PhoneMenu = ({ user, logout }) => {
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const isMenuOpen = Boolean(anchorElMenu);
  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <>
      <MenuItem
        onClick={handleClickMenu}
        size="small"
        sx={styles.menuItem}
        aria-controls={isMenuOpen ? "menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
      >
        <MenuIcon />
      </MenuItem>

      <Menu
        anchorEl={anchorElMenu}
        id="menu"
        open={isMenuOpen}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={styles.paperMenuProps}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {!user && (
          <Link href="/login" sx={styles.menuLink}>
            <MenuItem key="login" sx={styles.menuItem}>
              <LoginIcon sx={styles.icon} />
              <Typography>Connexion</Typography>
            </MenuItem>
          </Link>
        )}

        <Link href="/contact" sx={styles.menuLink}>
          <MenuItem key="contact" sx={styles.menuItem}>
            <ContactPageIcon sx={styles.icon} />
            <Typography>Contact</Typography>
          </MenuItem>
        </Link>
        {user && (
          <Box>
            <Link href="/themes" sx={styles.menuLink}>
              <MenuItem key="themes" sx={styles.menuItem}>
                <PhotoIcon sx={styles.icon} />
                <Typography>Thèmes</Typography>
              </MenuItem>
            </Link>
            <Link href="/recent" sx={styles.menuLink}>
              <MenuItem sx={styles.menuItem}>
                <HistoryIcon sx={styles.icon} />
                <Typography>Récent</Typography>
              </MenuItem>
            </Link>
            <Divider sx={{ my: 1 }} />
            <MenuItem key="logout" sx={styles.menuItem} onClick={logout}>
              <LogoutIcon sx={styles.icon} />
              <Typography>Déconnexion</Typography>
            </MenuItem>
          </Box>
        )}
      </Menu>
    </>
  );
};

export default PhoneMenu;
