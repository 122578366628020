import { useState } from "react";
import { motion } from "framer-motion";

import { UCAButton } from "../../utils/Styled";
import { styles } from "./Contact.style";

import Loading from "../../components/loading/Loading";
import ContactSent from "./ContactSent";

import {
  verifyContactData,
  postContact,
} from "../../api/contact/contactHandler";

import { Select, MenuItem } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AbcIcon from "@mui/icons-material/Abc";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import {
  Box,
  Card,
  TextField,
  InputAdornment,
  Typography,
  FormControl,
} from "@mui/material";

const Contact = () => {
  document.title = "Contact";
  const defaultErrorValues = {
    fullnameText: "",
    hasFullnameError: false,
    emailText: "",
    hasEmailError: false,
  };

  const defaultFormValues = {
    fullname: "",
    email: "",
    requestType: "",
    message: "",
  };

  const [errorValues, setErrorValues] = useState(defaultErrorValues);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState({
    hasError: false,
    message: "",
  });
  const [isSentModalOpen, setIsSentModalOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const resetErrors = () => {
    setErrorValues(defaultErrorValues);
    setApiError({
      hasError: false,
      text: "",
    });
  };

  const handleFormSubmit = async () => {
    setIsLoading(true);
    resetErrors();
    const aErrorValues = verifyContactData(formValues);

    if (aErrorValues.hasEmailError || aErrorValues.hasFullnameError) {
      setErrorValues(aErrorValues);
      setIsLoading(false);
      return;
    }

    try {
      await postContact(formValues);
      setIsLoading(false);
      setIsSentModalOpen(true);
    } catch {
      setApiError({
        hasError: true,
        text: "Une erreur est survenue, veuillez réessayer.",
      });
      setIsLoading(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card sx={styles.card}>
          <AssignmentIndIcon sx={{ ...styles.icon, mb: 1 }} />
          <Typography variant="overline" sx={{ fontSize: "10px" }}>
            Demande de contact
          </Typography>
          <FormControl sx={{ my: 1 }}>
            <Select
              labelId="request-type-label"
              value={formValues.requestType}
              sx={styles.field}
              onChange={(e) =>
                setFormValues({ ...formValues, requestType: e.target.value })
              }
              defaultValue={""}
              displayEmpty
            >
              <MenuItem value={""} disabled>
                Type de demande
              </MenuItem>
              <MenuItem value="account_creation">Création de compte</MenuItem>
              <MenuItem value="other_request">Autre demande</MenuItem>
            </Select>
            {formValues.requestType !== "" && (
              <>
                {formValues.requestType === "account_creation" && (
                  <TextField
                    sx={styles.field}
                    name="fullname"
                    type="text"
                    value={formValues.fullname}
                    error={errorValues.hasFullnameError}
                    helperText={errorValues.fullnameText}
                    onChange={handleInputChange}
                    placeholder="Nom complet"
                    variant="outlined"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon style={styles.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                <TextField
                  sx={styles.field}
                  name="email"
                  type="text"
                  value={formValues.email}
                  error={errorValues.hasEmailError}
                  helperText={errorValues.emailText}
                  onChange={handleInputChange}
                  placeholder="exemple@mail.com"
                  variant="outlined"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon style={styles.icon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  sx={styles.field}
                  name="message"
                  type="text"
                  multiline={true}
                  rows={3}
                  value={formValues.message}
                  onChange={handleInputChange}
                  placeholder="Message"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AbcIcon style={styles.icon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          </FormControl>
          <UCAButton
            sx={styles.submitButton}
            onClick={handleFormSubmit}
            disabled={formValues.requestType === ""}
          >
            Envoyer
          </UCAButton>
          {isLoading && <Loading />}
          {apiError.hasError && (
            <Typography variant="overline" sx={styles.errorText}>
              {apiError.text}
            </Typography>
          )}
        </Card>
      </motion.div>
      <ContactSent isOpen={isSentModalOpen} setIsOpen={setIsSentModalOpen} />
    </Box>
  );
};

export default Contact;
