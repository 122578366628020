export const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    py: 1,
    px: 3,
  },

  button: {
    width: "150px",
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  flexColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  tagsContainer: {
    mt: 1,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
  },

  subTitle: { mr: 1, fontSize: "12px" },

  errorText: {
    fontSize: "12px",
    color: "#C34D60",
    mt: 2,
  },

  field: {
    my: 2,
    backgroundColor: "white",

    minWidth: "300px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  menuProps: {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 10,
        width: 250,
      },
    },
  },

  icon: {
    color: "#027FA2",
  },
};
