import api from "../axios";

export const postContact = async (iData) => {
  const aResponse = await api.post("/contacts", iData);
  return aResponse.data;
};

export const verifyContactData = (iFormValues) => {
  let aErrorValues = {
    fullnameText: "",
    hasFullnameError: false,
    emailText: "",
    hasEmailError: false,
  };

  if (iFormValues.requestType === "account_creation") {
    if (!iFormValues.fullname) {
      aErrorValues.fullnameText = "Champ requis";
      aErrorValues.hasFullnameError = true;
    }
  }

  const aRegexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!aRegexEmail.test(iFormValues.email)) {
    aErrorValues.emailText = "Adresse mail invalide";
    aErrorValues.hasEmailError = true;
  }

  return aErrorValues;
};
