export const styles = {
  mainContent: {
    height: "calc(100vh - 75px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontSize: { xs: "2rem", md: "3rem" },
    mb: 4,
  },
  subHeaderText: {
    fontSize: { xs: "1rem", md: "1.6rem" },
    maxWidth: 700,
  },
};
