import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";
import { motion } from "framer-motion";

import { styles } from "./HomePage.style";
import { UCAButton } from "../../utils/Styled";

export default function HomePage({ user }) {
  document.title = "Accueil";
  return (
    <Container sx={styles.mainContent}>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5 }}
      >
        <Typography variant="h2" align="center" sx={styles.headerText}>
          Photothèque d'Université Côte d'Azur
        </Typography>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5 }}
      >
        <Typography variant="h5" align="center" sx={styles.subHeaderText}>
          Découvrez notre collection de photos de l'Université Côte d'Azur.
          Parcourez et explorez les moments précieux de la vie universitaire.
        </Typography>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5, delay: 0.5 }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          {user ? (
            <Link href={`/themes`} underline="none">
              <UCAButton>Accéder aux thèmes</UCAButton>
            </Link>
          ) : (
            <Link href={`/login`} underline="none">
              <UCAButton>Connexion</UCAButton>
            </Link>
          )}
        </Box>
      </motion.div>
    </Container>
  );
}
