import React from "react";
import { Container, Card, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";
import { UCAButton } from "../../utils/Styled";
import { styles } from "./NotFound.style";

const NotFound = () => {
  const navigateTo = useNavigate();

  return (
    <Container maxWidth={false} sx={styles.container}>
      <Card sx={styles.card}>
        <ErrorIcon sx={styles.errorIcon} />
        <Typography variant="h4" color="textPrimary" gutterBottom>
          404 - Page non trouvée
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Oups ! La page que vous recherchez n'existe pas.
        </Typography>
        <UCAButton sx={styles.button} onClick={() => navigateTo("/")}>
          Accueil
        </UCAButton>
      </Card>
    </Container>
  );
};

export default NotFound;
