import { useState } from "react";

import { IconButton, TableCell, TableRow, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditTheme from "./editTheme/EditTheme";

const ThemeRow = ({ theme, setThemes }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);

  const rowBody = (
    <>
      <TableCell align="center">{theme}</TableCell>
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <IconButton
            onClick={() => {
              setIsEditOpen(true);
            }}
          >
            <EditIcon sx={{ color: "#027FA2" }} />
          </IconButton>
        </Box>
      </TableCell>
    </>
  );

  return (
    <>
      <TableRow>{rowBody}</TableRow>
      <EditTheme
        theme={theme}
        setThemes={setThemes}
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
      />
    </>
  );
};

export default ThemeRow;
