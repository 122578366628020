import { useState } from "react";

import {
  putUser,
  verifyRegisterData,
} from "../../../../api/users/usersHandler";

import { UCAButton } from "../../../../utils/Styled";
import { styles } from "./EditUser.style";

import Loading from "../../../../components/loading/Loading";

import {
  Dialog,
  DialogTitle,
  TextField,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  DialogActions,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import AbcIcon from "@mui/icons-material/Abc";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const EditUser = ({ isOpen, setIsOpen, user, usergroups, setUsers }) => {
  const defaultErrorValues = {
    usernameText: "",
    hasUsernameError: false,
    fullnameText: "",
    hasFullnameError: false,
    emailText: "",
    hasEmailError: false,
    usergroupText: "",
    hasUsergroupError: false,
  };

  const defaultFormValues = {
    username: user.username,
    fullname: user.fullname,
    email: user.email,
    usergroup: user.usergroup,
  };

  const [errorValues, setErrorValues] = useState(defaultErrorValues);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState({
    hasError: false,
    text: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    setErrorValues(defaultErrorValues);
    setApiError({
      hasError: false,
      text: "",
    });
    const aErrorValues = verifyRegisterData(formValues);

    if (
      aErrorValues.hasEmailError ||
      aErrorValues.hasUsergroupError ||
      aErrorValues.hasFullnameError ||
      aErrorValues.hasUsernameError
    ) {
      setErrorValues(aErrorValues);
      setIsLoading(false);
      return;
    }

    try {
      const updatedUser = await putUser(user.ref, formValues);
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.ref === updatedUser.ref ? updatedUser : user
        )
      );
      setIsLoading(false);
      setIsOpen(false);
    } catch (error) {
      if (error.status === 409) {
        setApiError({
          hasError: true,
          text: "Un compte avec ce nom d'utilisateur existe déjà",
        });
      } else {
        setApiError({
          hasError: true,
          text: "Une erreur est survenue, veuillez réessayer.",
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <IconButton
        onClick={() => setIsOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={styles.container}>
        <DialogTitle>Modification de {user.fullname}</DialogTitle>
        <FormControl>
          <TextField
            sx={styles.field}
            name="fullname"
            type="text"
            value={formValues.fullname}
            error={errorValues.hasFullnameError}
            helperText={errorValues.fullnameText}
            onChange={handleInputChange}
            placeholder="Nom complet"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AbcIcon style={styles.icon} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={styles.field}
            name="username"
            type="text"
            value={formValues.username}
            error={errorValues.hasUsernameError}
            helperText={errorValues.usernameText}
            onChange={handleInputChange}
            placeholder="Nom d'utilisateur"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon style={styles.icon} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={styles.field}
            name="email"
            type="text"
            value={formValues.email}
            error={errorValues.hasEmailError}
            helperText={errorValues.emailText}
            onChange={handleInputChange}
            placeholder="exemple@mail.com"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon style={styles.icon} />
                </InputAdornment>
              ),
            }}
          />
          <FormControl error={errorValues.hasUsergroupError}>
            <Select
              labelId="quality-label"
              sx={styles.field}
              value={formValues.usergroup}
              onChange={(e) =>
                setFormValues({ ...formValues, usergroup: e.target.value })
              }
              defaultValue={""}
              variant="outlined"
              displayEmpty
            >
              <MenuItem value={""} disabled>
                Choisissez un rôle
              </MenuItem>
              {usergroups.map((aUserGroup) => {
                return (
                  <MenuItem value={aUserGroup.ref} key={aUserGroup.ref}>
                    <Typography>{aUserGroup.name}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>{errorValues.usergroupText}</FormHelperText>
          </FormControl>
        </FormControl>
        <DialogActions>
          <UCAButton sx={styles.submitButton} onClick={handleSave}>
            Valider
          </UCAButton>
        </DialogActions>
        {isLoading && <Loading />}
        {apiError.hasError && (
          <Typography variant="overline" sx={styles.errorText}>
            {apiError.text}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default EditUser;
