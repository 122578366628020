import React, { useState, useEffect, useCallback } from "react";

import { styles } from "./ManageUsergroups.style";
import { UCAButton } from "../../../utils/Styled";

import { getUserGroups } from "../../../api/usergroups/usergroupsHandler";
import { getThemes } from "../../../api/collections/collectionsHandler";

import UsergroupRow from "./UsergroupRow";
import Loading from "../../../components/loading/Loading";
import UnexpectedError from "../../../components/errors/UnexpectedError";
import AddUsergroup from "./addUsergroup/AddUsergroup";

import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const ManageUsergroups = () => {
  const [usergroups, setUsergroups] = useState([]);
  const [hasApiError, setHasApiError] = useState(false);
  const [isAddUsergroupOpen, setIsAddUsergroupOpen] = useState(false);
  const [themes, setThemes] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const aUsergroups = await getUserGroups();
      setUsergroups(aUsergroups);
      const aThemes = await getThemes();
      setThemes(aThemes);
    } catch {
      setHasApiError(true);
    }
  }, []);

  useEffect(() => {
    document.title = "Gestion des groupes d'utilisateurs";
    fetchData();
  }, [fetchData]);

  if (hasApiError) {
    return <UnexpectedError />;
  }

  if (!usergroups.length) {
    return <Loading />;
  }

  const usergroupRows = usergroups.map((iUsergroup) => (
    <UsergroupRow
      key={iUsergroup.ref}
      usergroup={iUsergroup}
      setUsergroups={setUsergroups}
      themes={themes}
    />
  ));

  return (
    <Box>
      <Typography variant="h2" sx={styles.title}>
        Gestion des groupes d'utilisateurs
      </Typography>
      <UCAButton
        sx={styles.addButton}
        onClick={() => {
          setIsAddUsergroupOpen(true);
        }}
      >
        <AddIcon sx={{ mr: 2 }} />
        <Typography>Ajouter un groupe d'utilisateur</Typography>
      </UCAButton>
      <Box sx={{ mx: "4%" }}>
        <TableContainer component={Paper} sx={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" align="center">
                  <strong>Identifiant</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Nom</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Permissions</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{usergroupRows}</TableBody>
          </Table>
          <Table>
            <TableHead sx={{ display: "flex", justifyContent: "start" }}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Typography>
                    <strong>Total des groupes :</strong> {usergroups.length}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Box>
      <AddUsergroup
        isOpen={isAddUsergroupOpen}
        setIsOpen={setIsAddUsergroupOpen}
        setUsergroups={setUsergroups}
        themes={themes}
      />
    </Box>
  );
};

export default ManageUsergroups;
