export const STORAGE_ACCESS_TOKEN = "accessToken";
export const STORAGE_USER_ID = "userId";

export const PHOTOS_VIEWS = {
  ALL_THEMES: "themes",
  THEME: "theme",
  ALBUM: "album",
};

export const PHOTOS_SEARCH_PARAMS = {
  VIEW: "view",
  ELEMENT_ID: "elementId",
  THEME_ID: "themeId",
};

export const FILES_ACCEPTED = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "tiff",
  "webp",
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "txt",
  "odt",
  "ods",
  "odp",
  "mp4",
  "avi",
  "mkv",
  "mov",
  "webm",
  "flv",
  "wmv",
  "mpeg",
  "mp3",
  "wav",
  "ogg",
  "flac",
  "aac",
  "m4a",
  "wma",
];

export const PERMISSION_DESCRIPTIONS = {
  r: "Gérer le contenu",
  u: "Gérer les utilisateurs",
  g: "Gérer les groupes d'utilisateurs",
};

export const RESOURCES_GROUPED_ACTIONS = {
  DELETE: "delete",
  DOWNLOAD: "download",
};

export const API_BASE_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:3000/" : "/api/";
