import { useState } from "react";

import { UCAButton } from "../../utils/Styled";
import { styles } from "./Login.style";

import { postToken } from "../../api/users/password/passwordHandler";

import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function AskResetPwdModal({ isOpen, setIsOpen }) {
  const [username, setUsername] = useState("");
  const [hasBeenSent, setHasBeenSent] = useState(false);

  const handlePasswordReset = async () => {
    setHasBeenSent(true);

    await postToken({ username: username });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="reset-password-dialog-title"
      aria-describedby="reset-password-dialog-description"
    >
      <IconButton
        onClick={() => setIsOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="reset-password-dialog-title">
        Réinitialisation du mot de passe
      </DialogTitle>

      {hasBeenSent ? (
        <DialogContent>
          <DialogContentText id="reset-password-dialog-description">
            Si le nom d'utilisateur entré est valide, un mail de
            réinitialisation de mot de passe vous a été envoyé.
          </DialogContentText>
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <DialogContentText id="reset-password-dialog-description">
              Veuillez entrer votre nom d'utilisateur afin de recevoir un lien
              de réinitialisation de mot de passe par mail.
            </DialogContentText>
            <Box sx={{ ...styles.flexCenter, my: 2 }}>
              <TextField
                autoFocus
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                label="Nom d'utilisateur"
                type="text"
                sx={styles.field}
                fullWidth
                variant="outlined"
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ ...styles.flexEvenly, mb: 2 }}>
            <UCAButton onClick={() => setIsOpen(false)} sx={{ width: "150px" }}>
              Annuler
            </UCAButton>
            <UCAButton onClick={handlePasswordReset} sx={{ width: "150px" }}>
              Envoyer
            </UCAButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
