import { useState } from "react";

import { IconButton, TableCell, TableRow, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteCollection from "./deleteCollection/DeleteCollection";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeywordsModal from "./KeywordsModal";
import EditCollection from "./editCollection/EditCollection";

const CollectionRow = ({ collection, setCollections }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isKeywordsModalOpen, setIsKeywordsModalOpen] = useState(false);

  const rowBody = (
    <>
      <TableCell component="th" align="center">
        <strong>{collection.ref}</strong>
      </TableCell>
      <TableCell align="center">{collection.name}</TableCell>
      <TableCell align="center">{collection.user}</TableCell>
      <TableCell align="center">
        {new Date(collection.created).toLocaleDateString("fr")}
      </TableCell>
      <TableCell align="center">
        {collection.public === 0 ? "Privée" : "Publique"}
      </TableCell>
      <TableCell align="center">{collection.theme}</TableCell>
      <TableCell align="center">
        <IconButton
          onClick={() => {
            setIsKeywordsModalOpen(true);
          }}
        >
          <VisibilityIcon sx={{ color: "#027FA2" }} />
        </IconButton>
      </TableCell>
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <IconButton
            onClick={() => {
              setIsEditOpen(true);
            }}
          >
            <EditIcon sx={{ color: "#027FA2" }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setIsDeleteOpen(true);
            }}
          >
            <DeleteForeverIcon sx={{ color: "#C34D60" }} />
          </IconButton>
        </Box>
      </TableCell>
    </>
  );

  return (
    <>
      <TableRow>{rowBody}</TableRow>
      <DeleteCollection
        collection={collection}
        setCollections={setCollections}
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
      />
      <EditCollection
        collection={collection}
        setCollections={setCollections}
        isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
      />
      <KeywordsModal
        collection={collection}
        isOpen={isKeywordsModalOpen}
        setIsOpen={setIsKeywordsModalOpen}
      />
    </>
  );
};

export default CollectionRow;
