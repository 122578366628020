import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { styles } from "./ManageThemes.style";

import { getThemes } from "../../../api/collections/collectionsHandler";
import ThemeRow from "./ThemeRow";

import Loading from "../../../components/loading/Loading";
import UnexpectedError from "../../../components/errors/UnexpectedError";
import { UCAButton } from "../../../utils/Styled";

import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  TableBody,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const ManageThemes = () => {
  const navigateTo = useNavigate();

  const [themes, setThemes] = useState();
  const [hasApiError, setHasApiError] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const aResponse = await getThemes();
      setThemes(aResponse);
    } catch {
      setHasApiError(true);
    }
  }, []);

  useEffect(() => {
    document.title = "Gestion des thèmes";
    fetchData();
  }, [fetchData]);

  if (hasApiError) {
    return <UnexpectedError />;
  }

  if (!themes) {
    return <Loading />;
  }

  const themeRows = themes.map((theme) => {
    return <ThemeRow key={theme} theme={theme} setThemes={setThemes} />;
  });

  return (
    <Box>
      <Typography variant="h2" sx={styles.title}>
        Gestion des thèmes
      </Typography>
      <UCAButton
        sx={styles.addButton}
        onClick={() => {
          navigateTo("/add-resources");
        }}
      >
        <AddIcon sx={{ mr: 2 }} />
        <Typography>Ajouter un thème</Typography>
      </UCAButton>
      <Box sx={{ mx: "4%" }}>
        <TableContainer component={Paper} sx={styles.tableContainer}>
          <Table sx={{ borderTop: "1px solid rgba(99, 99, 99, 0.2)" }}>
            <TableHead>
              <TableRow>
                <TableCell component="th" align="center" sx={{ width: "80%" }}>
                  <strong>Nom</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{themeRows}</TableBody>
          </Table>
          <Table>
            <TableHead sx={{ display: "flex", justifyContent: "start" }}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Typography>
                    <strong>Total de thèmes :</strong> {themes.length}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ManageThemes;
