import { useState, useEffect, useCallback } from "react";

import { PERMISSION_DESCRIPTIONS } from "../../../../utils/Constants";
import { postUserGroup } from "../../../../api/usergroups/usergroupsHandler";

import { UCAButton } from "../../../../utils/Styled";
import Loading from "../../../../components/loading/Loading";

import { styles } from "./AddUsergroup.style";

import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  DialogContent,
  Checkbox,
  FormControlLabel,
  DialogActions,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AddUsergroup = ({ isOpen, setIsOpen, setUsergroups, themes }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState({
    hasError: false,
    text: "",
  });
  const [permissions, setPermissions] = useState({});
  const [usergroup, setUsergroup] = useState({ name: "", permissions: "" });
  const [themeMode, setThemeMode] = useState("all");

  const initializePermissions = useCallback(() => {
    const newPermissions = {};
    Object.keys(PERMISSION_DESCRIPTIONS).forEach((key) => {
      newPermissions[key] = false;
    });

    newPermissions["t*"] = true;
    setPermissions(newPermissions);
  }, []);

  useEffect(() => {
    initializePermissions();
  }, [initializePermissions]);

  const handleCheckboxChange = (event) => {
    setPermissions({
      ...permissions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    setApiError({
      hasError: false,
      text: "",
    });

    if (!usergroup.name) {
      setApiError({
        hasError: true,
        text: "Veuillez renseigner un nom de groupe",
      });
      setIsLoading(false);
      return;
    }
    const updatedPermissions = Object.entries(permissions)
      .filter(([, isChecked]) => isChecked)
      .map(([key]) => key)
      .join(",");

    try {
      const aNewUsergroup = await postUserGroup({
        name: usergroup.name,
        permissions: updatedPermissions,
      });
      setUsergroups((prevUsergroups) => [...prevUsergroups, aNewUsergroup]);

      setIsLoading(false);
      handleClose();
    } catch {
      setApiError({
        hasError: true,
        text: "Une erreur est survenue, veuillez réessayer",
      });
      setIsLoading(false);
    }
  };

  const handleThemeModeChange = (event) => {
    setThemeMode(event.target.value);
    if (event.target.value === "all") {
      setPermissions({ ...permissions, "t*": true });
    } else {
      setPermissions({ ...permissions, "t*": false });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setUsergroup({ name: "", permissions: "" });
    initializePermissions();
  };

  const renderCheckboxes = () => {
    return Object.entries(PERMISSION_DESCRIPTIONS).map(([key, description]) => (
      <Box key={key}>
        <FormControlLabel
          control={
            <Checkbox
              checked={permissions[key] || false}
              onChange={handleCheckboxChange}
              name={key}
            />
          }
          label={description}
        />
        <br />
      </Box>
    ));
  };

  const renderThemeCheckboxes = () => {
    if (themeMode === "specific") {
      return themes.map((theme) => (
        <Box key={theme} sx={{ mt: "5px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions["t" + theme] || false}
                onChange={handleCheckboxChange}
                name={`t${theme}`}
              />
            }
            label={theme}
          />
          <br />
        </Box>
      ));
    }
    return null;
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Box sx={styles.container}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ textAlign: "center" }}>
          Ajouter un groupe d'utilisateurs
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <TextField
              label="Nom du groupe"
              variant="outlined"
              fullWidth
              sx={styles.field}
              value={usergroup.name}
              onChange={(event) =>
                setUsergroup({
                  ...usergroup,
                  name: event.target.value,
                })
              }
            />

            <Typography variant="h6">Permissions</Typography>
            <Box sx={styles.permissionsContainer}>
              {renderCheckboxes()}
              <Select
                value={themeMode}
                onChange={handleThemeModeChange}
                sx={styles.field}
              >
                <MenuItem value={"all"}>Tous les thèmes</MenuItem>
                <MenuItem value={"specific"}>Certains thèmes</MenuItem>
              </Select>
              {renderThemeCheckboxes()}
            </Box>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <UCAButton onClick={handleSave} sx={styles.button}>
            Ajouter
          </UCAButton>
        </DialogActions>
        {isLoading && <Loading />}
        {apiError.hasError && (
          <Typography variant="overline" sx={styles.errorText}>
            {apiError.text}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default AddUsergroup;
