import { FileUploader } from "react-drag-drop-files";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { styles } from "./AddResources.style";
import { UCAButton, UploaderBox } from "../../../../utils/Styled";
import { FILES_ACCEPTED } from "../../../../utils/Constants";

import Loading from "../../../../components/loading/Loading";
import UnexpectedError from "../../../../components/errors/UnexpectedError";

import {
  getTheme,
  getThemes,
  postCollection,
  putCollection,
} from "../../../../api/collections/collectionsHandler";
import {
  getUserGroups,
  putUserGroup,
} from "../../../../api/usergroups/usergroupsHandler";

import { Typography, Box, Select, MenuItem } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PhotoCard from "./PhotoCard";
import NewThemeBox from "./NewThemeBox";
import OldThemeBox from "./OldThemeBox";
import MetadataFields from "./MetadataFields";
import TagInput from "./TagInput";
import { postResource } from "../../../../api/resources/resourcesHandler";

const AddResources = ({ user }) => {
  const SELECT_NEW_THEME = "newTheme";
  const SELECT_NEW_ALBUM = "newAlbum";
  const navigateTo = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [themeSelected, setThemeSelected] = useState("");
  const [albumSelected, setAlbumSelected] = useState("");
  const [themeName, setThemeName] = useState("");
  const [albumName, setAlbumName] = useState("");
  const [themes, setThemes] = useState();
  const [albums, setAlbums] = useState();
  const [usergroups, setUsergroups] = useState();
  const [isPublic, setIsPublic] = useState(1);
  const [description, setDescription] = useState("");
  const [model, setModel] = useState("");
  const [credit, setCredit] = useState("");
  const [tagInput, setTagInput] = useState("");
  const [hasApiError, setHasApiError] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [tags, setTags] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      if (themeSelected) {
        const aAlbums = await getTheme(encodeURIComponent(themeSelected));
        setAlbums(aAlbums);
      } else {
        setAlbums([]);
      }
      const aThemes = await getThemes();
      setThemes(aThemes);
      const aUsergroups = await getUserGroups();
      setUsergroups(aUsergroups);
    } catch (error) {
      setHasApiError(true);
    }
  }, [themeSelected]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilesAdded = (photos) => {
    for (const aPhoto of photos) {
      setPhotos((prevPhotos) => {
        return [...prevPhotos, aPhoto];
      });
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      let theme;
      if (themeSelected === SELECT_NEW_THEME) {
        for (const group of selectedGroups) {
          await putUserGroup(group.ref, {
            name: group.name,
            permissions: `${group.permissions},j${themeName}`,
          });
        }
        theme = themeName;
      } else {
        theme = themeSelected;
      }

      let album;
      if (
        albumSelected === SELECT_NEW_ALBUM ||
        themeSelected === SELECT_NEW_THEME
      ) {
        const aNewAlbum = await postCollection({
          name: albumName,
          user: user.ref,
          theme: theme,
          public: isPublic,
          keywords: tags,
        });
        album = aNewAlbum.ref;
      } else {
        album = albumSelected;
        const albumToUpdate = albums.find((a) => a.ref === albumSelected);
        await putCollection(albumToUpdate.ref, {
          name: albumToUpdate.name,
          user: albumToUpdate.user,
          theme: albumToUpdate.theme,
          public: albumToUpdate.public,
          keywords: albumToUpdate.keywords.concat(tags),
        });
      }

      for (const photo of photos) {
        const formData = new FormData();
        formData.append("file", photo);
        formData.append("description", description);
        formData.append("model", model);
        formData.append("credit", credit);
        await postResource(album, formData);
      }

      navigateTo(`/themes/${encodeURIComponent(theme)}/${album}`);
    } catch {
      setHasSubmitError(true);
    }

    setIsLoading(false);
  };

  if (hasApiError) {
    return <UnexpectedError />;
  }

  if (!themes || !usergroups) {
    return (
      <Box sx={{ mt: 4 }}>
        <Loading />
      </Box>
    );
  }

  const renderTagInput = () => (
    <TagInput
      tags={tags}
      setTags={setTags}
      tagInput={tagInput}
      setTagInput={setTagInput}
    />
  );

  const renderMetadata = () => (
    <MetadataFields
      description={description}
      setDescription={setDescription}
      model={model}
      setModel={setModel}
      credit={credit}
      setCredit={setCredit}
    />
  );

  const renderFileUploader = () => (
    <FileUploader
      style={styles.uploader}
      children={uploadBox}
      handleChange={handleFilesAdded}
      name="file"
      types={FILES_ACCEPTED}
      label="Sélectionner ou glisser déposer un fichier."
      hoverTitle="Déposez ici"
      multiple
    />
  );

  const renderSaveButton = () => (
    <UCAButton
      sx={{ width: "200px", margin: "auto" }}
      onClick={handleSubmit}
      disabled={photos.length === 0 || tags.length === 0}
    >
      Sauvegarder
    </UCAButton>
  );

  const uploadBox = (
    <UploaderBox sx={styles.uploader}>
      <CloudUploadIcon sx={styles.icon} />
      <Typography sx={{ mb: 2 }}>
        <strong style={{ textDecoration: "underline" }}>Sélectionnez</strong> ou
        glissez pour importer des images
      </Typography>
      <Typography>Formats acceptés : Photos, vidéos, documents</Typography>
    </UploaderBox>
  );

  return (
    <Box sx={styles.container}>
      <Typography variant="h4" textAlign={"center"} sx={{ my: 3 }}>
        Ajoutez des ressources
      </Typography>
      {isLoading && <Loading />}
      <Box sx={{ display: isLoading ? "none" : "block" }}>
        <Box sx={styles.flexCenter}>
          <Box sx={styles.uploadContainer}>
            <Select
              sx={styles.field}
              MenuProps={styles.menuProps}
              onChange={(e) => {
                setThemeSelected(e.target.value);
                setAlbumSelected("");
              }}
              displayEmpty
              defaultValue={""}
            >
              <MenuItem value={""} disabled>
                Sélectionnez un thème
              </MenuItem>
              <MenuItem value={SELECT_NEW_THEME}>
                <Typography>Nouveau thème</Typography>
              </MenuItem>
              {themes.map((theme, index) => (
                <MenuItem key={index} value={theme}>
                  <Typography>{theme}</Typography>
                </MenuItem>
              ))}
            </Select>
            {themeSelected && (
              <>
                {themeSelected === SELECT_NEW_THEME ? (
                  <>
                    <NewThemeBox
                      themeName={themeName}
                      setThemeName={setThemeName}
                      albumName={albumName}
                      setAlbumName={setAlbumName}
                      isPublic={isPublic}
                      setIsPublic={setIsPublic}
                      usergroups={usergroups}
                      selectedGroups={selectedGroups}
                      setSelectedGroups={setSelectedGroups}
                      renderTagInput={renderTagInput}
                      renderMetadata={renderMetadata}
                      renderFileUploader={renderFileUploader}
                      renderSaveButton={renderSaveButton}
                    />
                  </>
                ) : (
                  <>
                    <OldThemeBox
                      albumSelected={albumSelected}
                      setAlbumSelected={setAlbumSelected}
                      albumName={albumName}
                      setAlbumName={setAlbumName}
                      albums={albums}
                      isPublic={isPublic}
                      setIsPublic={setIsPublic}
                      renderTagInput={renderTagInput}
                      renderMetadata={renderMetadata}
                      renderFileUploader={renderFileUploader}
                      renderSaveButton={renderSaveButton}
                    />
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
        {hasSubmitError && (
          <Typography textAlign={"center"} sx={{ my: 2, color: "#C34D60" }}>
            Une erreur est survenue, veuillez réessayer
          </Typography>
        )}
        <PhotoCard photos={photos} setPhotos={setPhotos} />
      </Box>
    </Box>
  );
};

export default AddResources;
