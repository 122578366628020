import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getCollections } from "../../../api/collections/collectionsHandler";

import { styles } from "./ManageCollection.style";

import CollectionRow from "./CollectionRow";
import Loading from "../../../components/loading/Loading";
import UnexpectedError from "../../../components/errors/UnexpectedError";
import { UCAButton } from "../../../utils/Styled";

import {
  Box,
  TextField,
  InputAdornment,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  FormControl,
  TableBody,
  Pagination,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

const ManageCollections = () => {
  const navigateTo = useNavigate();

  const [searchResult, setSearchResult] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [collections, setCollections] = useState();
  const [hasApiError, setHasApiError] = useState(false);

  const handleSearchChange = (iEvent) => {
    setSearchValue(iEvent.target.value);
    if (iEvent.target.value.length >= 2) {
      setPageNumber(1);
      setSearchResult(iEvent.target.value);
    } else {
      setSearchResult("");
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const aResponse = await getCollections(pageNumber, 30, searchResult);
      setCollections(aResponse.collections);
      setPageCount(aResponse.pageCount);
      setTotalCount(aResponse.totalCount);
    } catch {
      setHasApiError(true);
    }
  }, [pageNumber, searchResult]);

  useEffect(() => {
    document.title = "Gestion des albums";
    fetchData();
  }, [fetchData]);

  const handlePaginationChange = (iEvent, iValue) => {
    setPageNumber(iValue);
  };

  if (hasApiError) {
    return <UnexpectedError />;
  }

  if (!collections) {
    return <Loading />;
  }

  const collectionRows = collections.map((collection) => {
    return (
      <CollectionRow
        key={collection.ref}
        collection={collection}
        setCollections={setCollections}
      />
    );
  });

  return (
    <Box>
      <Typography variant="h2" sx={styles.title}>
        Gestion des albums
      </Typography>
      <UCAButton
        sx={styles.addButton}
        onClick={() => {
          navigateTo("/add-resources");
        }}
      >
        <AddIcon sx={{ mr: 2 }} />
        <Typography>Ajouter un album</Typography>
      </UCAButton>
      <Box sx={{ mx: "4%" }}>
        <TableContainer component={Paper} sx={styles.tableContainer}>
          <Table sx={styles.headTable}>
            <TableHead size="medium" sx={styles.headTableHead}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <FormControl>
                    <TextField
                      id="search-input"
                      name="search"
                      placeholder="Rechercher un album..."
                      type="text"
                      variant="outlined"
                      value={searchValue}
                      onChange={handleSearchChange}
                      sx={styles.searchBar}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ color: "#027FA2" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Pagination
                    count={pageCount}
                    page={pageNumber}
                    onChange={handlePaginationChange}
                    sx={styles.pagination}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Table sx={{ borderTop: "1px solid rgba(99, 99, 99, 0.2)" }}>
            <TableHead>
              <TableRow>
                <TableCell component="th" align="center">
                  <strong>Identifiant</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Nom</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Utilisateur</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Date de création</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Portée</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Thème</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Mots clés</strong>
                </TableCell>
                <TableCell component="th" align="center">
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{collectionRows}</TableBody>
          </Table>
          <Table>
            <TableHead sx={{ display: "flex", justifyContent: "start" }}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Typography>
                    <strong>Total d'albums :</strong> {totalCount}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead sx={{ display: "flex", justifyContent: "end" }}>
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Pagination
                    count={pageCount}
                    page={pageNumber}
                    onChange={handlePaginationChange}
                    sx={styles.pagination}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ManageCollections;
