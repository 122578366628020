export const styles = {
  container: {
    height: "calc(100vh - 250px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    p: 3,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "20px",
    textAlign: "center",
    width: "400px",
    color: "#C34D60",
  },
  errorIcon: {
    fontSize: "5rem",
    color: "#027fa2",
  },
  button: {
    mt: "1rem",
    width: "150px",
  },
};
