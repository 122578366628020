export const styles = {
  searchBar: {
    backgroundColor: "white",
    borderRadius: "20px",
    width: "300px",
    mt: 2,
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  pagination: {
    mx: { xs: 0, md: 2 },
    my: 2,
    ul: {
      [`& .MuiPaginationItem-root`]: {
        color: "#027FA2",
      },
    },
  },

  searchContainer: {
    display: "flex",
    justifyContent: { xs: "center", md: "end" },
    alignItems: "center",
    px: { xs: 0, md: 4 },
    py: 2,
  },

  paginationContainer: {
    display: "flex",
    justifyContent: { xs: "center", md: "end" },
    alignItems: "center",
    px: { xs: 0, md: 4, lg: 6 },
  },
};
