export const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    py: 1,
    px: 3,
  },

  field: {
    display: "flex",
    justifyContent: "center",
    my: 3,
    backgroundColor: "white",
    borderRadius: "20px",
    width: "350px",
    [`& fieldset`]: {
      borderRadius: "20px",
      border: "1px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },

  errorText: {
    fontSize: "12px",
    color: "#C34D60",
    mt: 2,
  },

  button: {
    width: "150px",
  },

  permissionsContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "300px",
    gap: 1,
  },
};
