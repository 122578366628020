import { API_BASE_URL } from "./Constants";
import { saveAs } from "file-saver";
import JSZip from "jszip";

export const fileSizeToString = (iSize) => {
  let koSize = iSize / 1024;
  if (koSize < 1000) {
    return koSize.toFixed(2) + " Ko";
  } else {
    let moSize = koSize / 1024;
    return moSize.toFixed(2) + " Mo";
  }
};

export const retrievePath = (ref, type, extension) => {
  const refStr = ref.toString();
  let path = `${API_BASE_URL}filestore`;
  path += `/${refStr[0]}`;
  for (let i = 0; i < refStr.length; i++) {
    path += `/${refStr[i]}`;
  }

  path += `_d/${ref}${type}.${extension}`;
  return path;
};

export const handleDownloads = async (selectedResources, quality) => {
  const zip = new JSZip();

  const getImagePath = (resourceRef, extension, quality) => {
    if (quality === "high") {
      return retrievePath(resourceRef, "", extension);
    } else if (quality === "lpr") {
      return retrievePath(resourceRef, "lpr", "jpg");
    } else if (quality === "scr") {
      return retrievePath(resourceRef, "scr", "jpg");
    }
  };

  const downloadResource = async (resourceRef, extension) => {
    const url = getImagePath(resourceRef, extension, quality);
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  };

  const formatFileName = (resourceRef, quality, extension, photographer) => {
    const photographerName = photographer || "INCONNU";
    if (quality === "high") {
      return `Univ_Cote_Dazur_©${photographerName}_${resourceRef}.${extension}`;
    } else {
      return `Univ_Cote_Dazur_©${photographerName}_${resourceRef}.jpg`;
    }
  };

  const resourcePromises = Array.from(selectedResources).map(
    async (resource) => {
      const resourceRef = resource.ref;
      const extension = resource.extension;
      const blob = await downloadResource(resourceRef, extension);
      const photographer = resource.metadata.Credit;
      const fileName = formatFileName(
        resourceRef,
        quality,
        extension,
        photographer
      );
      zip.file(fileName, blob);
    }
  );

  await Promise.all(resourcePromises);

  const zipBlob = await zip.generateAsync({ type: "blob" });
  saveAs(zipBlob, "Univ_Cote_Dazur_Phototheque.zip");
};

export const isSameResource = (a, b) => a.ref === b.ref;

export const getFileType = (extension) => {
  const photoExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp"];
  const documentExtensions = [
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "txt",
    "odt",
    "ods",
    "odp",
  ];
  const videoExtensions = [
    "mp4",
    "avi",
    "mkv",
    "mov",
    "webm",
    "flv",
    "wmv",
    "mpeg",
  ];
  const audioExtensions = ["mp3", "wav", "ogg", "flac", "aac", "m4a", "wma"];

  const lowerCaseExt = extension.toLowerCase();

  if (photoExtensions.includes(lowerCaseExt)) {
    return "Photo";
  } else if (documentExtensions.includes(lowerCaseExt)) {
    return "Document";
  } else if (videoExtensions.includes(lowerCaseExt)) {
    return "Video";
  } else if (audioExtensions.includes(lowerCaseExt)) {
    return "Audio";
  } else {
    return null;
  }
};

export const formatBytes = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "Ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + " " + sizes[i];
};
