import { useState } from "react";

import { RedButton, UCAButton } from "../../../../utils/Styled";
import { styles } from "./DeleteCollection.style";

import { deleteCollection } from "../../../../api/collections/collectionsHandler";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DeleteCollection = ({
  collection,
  setCollections,
  isOpen,
  setIsOpen,
}) => {
  const [hasApiError, setHasApiError] = useState(false);
  const handleDelete = async () => {
    try {
      await deleteCollection(collection.ref);
      setCollections((prevCollections) =>
        prevCollections.filter((c) => c.ref !== collection.ref)
      );
    } catch {
      setHasApiError(true);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <IconButton
        onClick={() => setIsOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={styles.container}>
        <DialogTitle>Suppresion {collection.name}</DialogTitle>
        <DialogContent>
          Êtes vous sûr(e) de vouloir supprimer l'album {collection.name} ainsi
          que l'intégralité de son contenu ?
        </DialogContent>
        <DialogActions sx={styles.flexSpace}>
          <UCAButton onClick={() => setIsOpen(false)} sx={styles.button}>
            Annuler
          </UCAButton>
          <RedButton onClick={handleDelete} sx={styles.button}>
            Supprimer
          </RedButton>
        </DialogActions>
        {hasApiError && (
          <Typography variant="overline" sx={styles.errorText}>
            Une erreur est survenue, veuillez réessayer.
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default DeleteCollection;
